import { NavRoute } from "app/shared/navigation/nav.route.interface";
import { BusinessAreaEnum } from "../enums/generated/BusinessAreaEnum";
import { PermissionRole } from "../enums/generated/PermissionRole";
import { SecurityService } from "../security/security.service";
import { FaBusinessAreaIconsService } from "app/shared/fa-business-area-icons/fa-business-area-icons.service";

export const getReportingNav: () => NavRoute = () => {
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.
    const icons = new FaBusinessAreaIconsService();
	return {
		name: 'Reporting'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Reports ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.Reports)
		, area: 'reporting'
        , href: 'reporting/list'
		, children: [
			{
				name: 'Reports'
				, exact: true
				, path: 'reporting/list'
				, activeNavLink: 'reporting'
				, secondaryNav: ['Reporting', 'Reports']
			}
			, {
				name: 'Admin'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Reports ]
						, permissionRoles: [ ...sec.setMinRole(p.SystemAdmin) ]
					}
				]
				, children: [
					{
						name: 'Facility Users'
						, exact: true
						, path: 'reporting/admin/facility-users/list'
						, activeNavLink: 'reporting/admin/facility-users'
						, secondaryNav: ['Reporting', 'Admin', 'Facility Users']
					}
				]
			}
            , {
                name: "CI Repack"
                , moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ ...sec.setMinRole(p.Technician) ]
					}
				]
                , children: [
					{
						name: 'Card Prod Status'
						, exact: true
						, path: 'reporting/ci/card-production-status/list'
						, activeNavLink: 'reporting/ci/card-production-status'
						, secondaryNav: ['Reporting', 'CI Repack', 'Card Prod Status']
					}
                    , {
						name: 'Card Ship Status'
						, exact: true
						, path: 'reporting/ci/card-shipment-status/list'
						, activeNavLink: 'reporting/ci/card-shipment-status'
						, secondaryNav: ['Reporting', 'CI Repack', 'Card Ship Status']
					}
				]
            }
		]
	};
};
