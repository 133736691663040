<button 
	class="btn btn-sm align-items-center" 
	[routerLink]="backLink" 
	[ngbTooltip]="(!isUser ? 'Return to Rx Fulfillment List' : 'Return to Refill Request List')"
>
	<fa-icon 
		rotate='90' 
		[icon]='faLevelDownAlt' 
		class='mr-2'
	></fa-icon>
	Back
</button>

<ng-template 
    #commentsModal 
    let-modal
>
	<pcg-shipment-comments-modal 
		[modal]="modal" 
		[shipmentId]="shipmentId" 
		[isUser]="isUser"
		id="commentsModal"
	></pcg-shipment-comments-modal>
</ng-template>

@if (isNotActive) { <div class="alert alert-danger text-center">This fulfillment has been deactivated. Click the checkmark to reactivate it.</div> }

<div class='clearfix row'>
	<div class='col-md'>
		<div class='float-left d-flex'>
            @if (!isUser) {
                <span class="page-title">
                    {{ shipmentId === 0 ? "Create" : "Edit" }} Rx {{ isFacility ? "CI/CHD" : "Home" }} Fulfillment
                    @if (shipmentId) { <span>| {{ shipmentId }}</span> }
                </span>
            } @else {
                <span class="page-title-user">
                    {{ shipmentId === 0 ? "Create" : "Edit" }} {{ facilityName }} Refill Request
                    @if (shipmentId) { <span>| {{ shipmentId }}</span> }
                </span>
            }
		</div>
	</div>

	@if (
		currentStep >=2 
		&& isPcg
	) {
		<button 
			ngbTooltip="PCG test zebra api" 
			class="page-action print-page" 
			(click)='testZebraApi()'
		></button>
	}

	<div 
		class='p-1 m-2 col-md-2' 
		[class.d-none]='
			(
				currentStep !== 2 
				|| stepper.selectedIndex !== 2
			) 
			&& (
				currentStep < 3 
				|| stepper.selectedIndex !== 3
			)
		'
	>
		<pcg-select 
			#printerSelect 
			[getDataOnInit]="false" 
			placeholder='Select a Printer'
			[(ngModel)]='shipmentPrinterId' 
			(change)="savePrinter()"
			ngbTooltip='Select the printer that the FEDEX or UPS labels will be printed on.'
		></pcg-select>
	</div>

	<div id="pageActions" class="p-1">
		@if (canEditDeliveryType) {
			<button 
				class='truckBtn' 
				(click)='changeDeliveryType()' 
				ngbTooltip='Change Delivery Type'
			><fa-icon [icon]='faTruck'></fa-icon></button>
		}
		@if (currentStep >=2) {
			<button 
				ngbTooltip="Packing Slip" 
				class="page-action print-page" 
				(click)='getPackingSlip()'
			></button>
		}
		<button 
			ngbTooltip="Comments" 
			class="page-action" 
			(click)='openCommentsModal(commentsModal, shipmentId)'
		>
			<pcg-fa-num-icon 
				[iconName]="faIconName.faCommentsRegular" 
				[num]="commentsCount"
				[numColor]="faNumColor.success" 
				[numPosition]='commentPosition'
			></pcg-fa-num-icon>
		</button>
		@if (auditHistoryAccess) {
			<button 
				ngbTooltip="Audit History" 
				class="page-action"
				[routerLink]='"/pharmacy/inventories/fulfillment/audit-history/" + shipmentId' 
				pcg-svg-history
			></button>
		}
		@if (!isUser) {
			@if (isNotActive) {
				<button 
					class="page-action" 
					(click)='setActivation()' 
					ngbTooltip='Click to activate this fulfillment.'
				><fa-icon [icon]='faCheck'></fa-icon></button>
			} @else {		
				<button 
					class="page-action" 
					(click)='setActivation()' 
					[ngbTooltip]="isComplete ? 'Complete fulfillments cannot be deactivated' : 'Click to deactivate this fulfillment.'"
                    [disabled]='isComplete'
				><fa-icon [icon]='faTimes'></fa-icon></button>
			}
		}
	</div>
</div>
<hr />

<pcg-stepper 
	#stepper 
	linear 
	(selectionChange)='reloadContent($event)'
>
	<cdk-step 
		[label]="!isUser ? 'Step 1: Product Prep' : 'Refill Request'" 
		[completed]='currentStep > 0'
	>
		<!-- Only rendering this component when on the current step to prevent conflicting DOM priorities -->
		@if (
			selectedStep == 0 
			|| selectedStep == undefined 
			|| !selectedStep
			|| isUser
		) {
			<pcg-rx-fulfillment-prescriptions
				#prescriptions 
				[inventoryId]="inventoryId" 
				[shipmentId]="shipmentId" 
				[canEdit]="currentStep < 2 && canEdit"
				[isFacility]="isFacility" 
				[currentStep]="currentStep" 
				[userSubmitted]="userSubmitted"
				(continue)='completeStep1()' 
				(back)='uncompleteStep1()' 
				[qcCheckComplete]='qcCheck?.qcCheckComplete'
			></pcg-rx-fulfillment-prescriptions>
		}		
	</cdk-step>

	@if (!isUser) {
		<cdk-step 
			label='Step 2: Shipping Prep' 
			[completed]='currentStep > 1'
		>
			<!-- Only rendering this component when on the current step to prevent conflicting DOM priorities -->
			@if (selectedStep == 1) {
				<pcg-rx-fulfillment-packages 
					#packages 
					[shipmentId]='shipmentId' 
					[inventoryId]='inventoryId'
					[currentStep]='currentStep' 
					(reload)='reloadStep4()' 
					(continue)='completeStep2();' 
					[canEdit]="!isComplete && canEdit" 
					(back)='stepper.previous()' 
					[qcCheckComplete]='qcCheck?.qcCheckComplete'
				></pcg-rx-fulfillment-packages>
			}
		</cdk-step>

		<cdk-step 
			label='Step 3: QC Check' 
			[completed]='currentStep > 2' 
			(click)="qcCheck.reloadPage();"
		>
			<!-- Only hiding the QC check component since other component need to reference it -->
			<pcg-qc-check 
				#qcCheck 
				[shipmentId]='shipmentId' 
				[inventoryId]='inventoryId' 
				[isFacility]='isFacility' 
				[class.d-none]="selectedStep != 2"
				(continue)='completeStep3()' 
				(back)='stepper.previous()' 
				(undo)='undoQcCheck()' 
				[currentStep]='currentStep'
			></pcg-qc-check>
		</cdk-step>

		<cdk-step 
			label='Step 4: Complete Fulfillment' 
			[completed]='currentStep > 3'
		>
			<!-- Only hiding this component when on the current step to prevent conflicting DOM priorities -->
			<pcg-submit 
				#submit 
				[shipmentId]='shipmentId' 
				[inventoryId]='inventoryId' 
				[class.d-none]="selectedStep != 3"
				[shipmentPrinterId]='shipmentPrinterId' 
                [isUser]="isUser"
				(back)='stepper.previous()' 
				(complete)='submitShipment()'
			></pcg-submit>
		</cdk-step>
	}
</pcg-stepper>