<form [formGroup]="formGroup">
	<div class="modal-header bg-white rounded">
		<h5 
			class="modal-title" 
			id="modal-basic-title"
		>Fulfillment Comments</h5>
		<button 
			type="button" 
			class="close" 
			aria-label="Close" 
			(click)="modal.dismiss()"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<input 
			type='hidden' 
			formControlName="shipmentId" 
		/>
		<input 
			type='hidden' 
			formControlName="countPfsFormIds" 
		/>

		@if (countPfs > 0) {
			<div class="row">
				<div class="col-md-4">PFS Form</div>
				@if (countPfs === 1) {
					<div class="col-md-8">
						<a 
							href="{{pfsFormIdLink}}" 
							target="_blank"
						>{{ formId }}</a>
						<input 
							type='hidden' 
							formControlName="pfsFormID" 
						/>
					</div>
				} @else if (countPfs > 1) {
					<div class="col-md-8">
						<pcg-select 
							#pfsFormSelect 
							pcgControlRef 
							formControlName="pfsFormID"
							dataSource="api/Select/PfsRefillForms" 
							[queryParams]="{ siteId: inventoryId, shipmentId: shipmentId }"
							ngbTooltip='Select the PFS Form this comment will be associated to.' 
							placeholder='Select a PFS Form'
						></pcg-select>
					</div>
				}
				<br /><br />
			</div>
		}

		<pcg-conversations
			[parentId]="shipmentId"
			[getUrl]="'api/Inventory/' + (isUser ? 'RxFulfillmentUser' : 'Shipping') + '/GetComment'"
			[saveUrl]="'api/Inventory/' + (isUser ? 'RxFulfillmentUser' : 'Shipping') + '/SaveComment'"
			[deleteUrl]="'api/Inventory/' + (isUser ? 'RxFulfillmentUser' : 'Shipping') + '/DeleteComment'"
			[showCommenterInfo]="true"
			[fromFulfillment]="true"
			[pfsFormId]="formGroup.controls.pfsFormID.value"
		></pcg-conversations>
	</div>
</form>