import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { PatientDataVm } from 'app/shared/generated/Models/PatientDataVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { SecurityService } from '../../core/security/security.service';
import { RxDataStorageService } from '../services/rx-data-storage.service';

@Component({
    selector: 'pcg-inv-choice-modal',
    templateUrl: './inv-choice-modal.component.html',
    styleUrls: ['./inv-choice-modal.component.scss'],
    standalone: false
})
export class InvChoiceModalComponent implements OnInit {

	@Input() modal: any;
	selectPfsForm: PcgSelectComponent;

	patientDataFormGroup = PatientDataVm.Form;
	patientDataModel = PatientDataVm.GetModel(this.patientDataFormGroup);
	model: PatientDataVm;

	hasMultipleInvChoices = false;
	title = "Create New";
	subTitle: string;

	canEdit: boolean;

	constructor(
		private sec: SecurityService
		, public router: Router
		, private ms: SystemMessageService
		, public rxDataStorageService: RxDataStorageService
		, private httpClient: HttpClient
		, private formatHelper: FormatHelperService
	) {	}

	ngOnInit() {
		this.canEdit = this.sec?.hasModuleAccess([BusinessAreaEnum.Inventory], SecurityService.setMinRole(PermissionRole.Technician));
		this.subTitle = this.rxDataStorageService.IsFacility 
			? "CI/CHD Delivery" 
			: "Home Delivery";
		this.title = "Create New " + this.subTitle;
	}

	scanSuccessInventoryList($event: PatientDataVm) {
		if (!this.formatHelper.GetIsNully($event.errorMessage)) { this.ms.setSystemMessage($event.errorMessage, 'error'); }
		else if ($event.inventories?.length === 0) {
			this.ms.setSystemMessage(
				`Product NDC (${this.formatHelper.FormatNdc($event.ndc)}) not found for any inventory. Please add the NDC to an inventory.`
				, 'warning'
			);
		} else if ($event.inventories?.length === 1) {
			//Head straight to a new RxFulfillment
			this.chooseInventory($event.inventories[0].inventorySiteId, $event.inventories[0].inventoryName);
		} else if ($event.inventories?.length > 1) {
			//Set the modal up for the user to select an inventory choice
			this.title = "Choose an Inventory for " + this.subTitle;
			this.hasMultipleInvChoices = true;
			this.model = $event;
		} else { this.ms.setSystemMessage(`There were no inventories found for Rx ${$event.rxNumber}`, 'error'); }
	}

	chooseInventory(
		invId: number
		, invName: string
	) {
		let isConfirmed = true;

		if (this.hasMultipleInvChoices) { isConfirmed = confirm(`Inventory: ${invName} was selected. Is this the correct inventory?`); }

		if (!isConfirmed) { return null; }
		else {
			const patientData: PatientDataVm = this.rxDataStorageService.PatientData;
			patientData.isFacility = this.rxDataStorageService.IsFacility;
			
			this.httpClient.post(`api/Inventory/QS1/ValidateAndSaveRxData/?inventorySiteId=${invId}`, patientData)
				.subscribe((returnData: PatientDataVm) => {
					if (
						returnData.errorMessage === null 
						|| returnData.errorMessage === undefined
					) {
						//Need to write over the old patient data since there is a facility address check that may provide new info not available ealier
						if (
							returnData.address !== patientData.address 
							|| returnData.address2 !== patientData.address2 
							|| returnData.city !== patientData.city
							|| returnData.state !== patientData.state 
							|| returnData.zip !== patientData.zip
						) { this.rxDataStorageService.PatientData = returnData; }

						//Now that validation has passed, and a shipment has been created, we can navigate to it
						this.router.navigateByUrl('/pharmacy/inventories/fulfillment/edit/'
							+ encodeURIComponent(`${returnData.shipmentId}`) + '/' + encodeURIComponent(`${invId}`));
						this.modal?.dismiss();
					} else { this.ms.setSystemMessage(returnData.errorMessage, 'error'); }
				},
				(error) => {//Dismiss the modal if security was revoked
					this.modal.dismiss();
				}
			);
		}
	}
}
