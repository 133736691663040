import { Component, inject, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { NavigationService } from '../navigation.service';
import { QuickNavService } from './quick-nav.service';

// This component places a small button in the top right corner of the screen (within the secondary nav)
// that opens any projected content below the secondary nav.
//
// Example:
//
//  <pcg-quick-nav>
//      {{ insert projected content / components here }}
//  </pcg-quick-nav>

@Component({
    selector: 'pcg-quick-nav',
    templateUrl: './quick-nav.component.html',
    styleUrls: ['./quick-nav.component.scss'],
    standalone: false
})
export class QuickNavComponent {
    quickNavService = inject(QuickNavService);
    navService = inject(NavigationService);

    // Optional icon input, defaults to ellipsis
    @Input() icon: IconDefinition = faEllipsisV;
    @Input() tooltip: string;
}
