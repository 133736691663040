import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { availableModules, SecurityService } from '../security/security.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getAdminNav: () => NavRoute = () => {
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.
    const icons = new FaBusinessAreaIconsService();

	//#region Children
	const dashboard: NavRoute = {
		name: 'Dashboard'
		, exact: true
		, path: 'admin/dashboard'
		, activeNavLink: 'admin/dashboard'
		, secondaryNav: ['Admin', 'Dashboard']
	}

	const organizations: NavRoute = {
		name: 'Organizations'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
		, children: [
			{
				name: 'Organizations'
				, exact: true
				, path: 'admin/organizations/orgs/list'
				, activeNavLink: 'admin/organizations/orgs'
				, secondaryNav: ['Admin', 'Organizations', 'Organizations']
			}
			, {
				name: 'Account Update Requests'
				, shortName: 'Account Updates'
				, exact: true
				, path: 'admin/organizations/account-request-queue'
				, activeNavLink: 'admin/organizations/account-request-queue'
				, secondaryNav: ['Admin', 'Organizations', 'Account Update Requests']
			}
			, {
				name: 'Facilities'
				, path: 'admin/organizations/facilities/list'
				, activeNavLink: 'admin/organizations/facilities'
				, secondaryNav: ['Admin', 'Organizations', 'Facilities']
			}
			, {
				name: 'Medical Directors'
				, exact: true
				, path: 'admin/organizations/medical-directors'
				, activeNavLink: 'admin/organizations/medical-directors'
				, secondaryNav: ['Admin', 'Organizations', 'Medical Directors']
			}
			, {
				name: 'Master Accounts'
				, exact: true
				, path: 'admin/organizations/master-accounts/list'
				, activeNavLink: 'admin/organizations/master-accounts'
				, secondaryNav: ['Admin', 'Organizations', 'Master Accounts']
			}
		]
	}

	const inventoryConfig: NavRoute = {
		name : 'Inventory Configuration'
		, shortName: 'Inv Configuration'
		, moduleAccess: [
			{
				modules: [BusinessAreaEnum.Admin]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
			}
		]
		, children : [
			{
				name : 'Programs'
				, path: 'admin/inventory-configuration/programs/list'
				, activeNavLink: 'admin/inventory-configuration/programs'
				, secondaryNav: ['Admin', 'Inventory Configuration', 'Programs']
			}
			, {
				name: 'Wholesale Account Groups'
				, shortName: 'WAG'
				, path: 'admin/inventory-configuration/wag'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: [PermissionRole.SystemAdmin]
					}
				]
				, activeNavLink: 'admin/inventory-configuration/wag'
				, secondaryNav: ['Admin', 'Inventory Configuration', 'Wholesale Account Groups']
			}
			, {
				name: 'Global Formulary'
				, path: 'admin/inventory-configuration/formulary/list'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
				, activeNavLink: 'admin/inventory-configuration/formulary'
				, secondaryNav: ['Admin', 'Inventory Configuration', 'Global Formulary']
			}
			, {
				name: 'Global Formulary Settings'
				, shortName: 'Formulary Settings'
				, path: 'admin/inventory-configuration/settings'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
				, activeNavLink: 'admin/inventory-configuration/settings'
				, secondaryNav: ['Admin', 'Inventory Configuration', 'Global Formulary Settings']
			}
			, {
				name: 'Suppliers'
				, path: 'admin/inventory-configuration/suppliers/list'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
				, activeNavLink: 'admin/inventory-configuration/suppliers'
				, secondaryNav: ['Admin', 'Inventory Configuration', 'Suppliers']
			}
		]
	}

	const shipping: NavRoute = {
		name: 'Shipping'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ 
					PermissionRole.SystemAdmin
					, PermissionRole.Pharmacist 
				]
			}
		]
		, children: [
			{
				name: 'Accounts'
				, exact: true
				, path: 'admin/shipping/accounts/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ PermissionRole.SystemAdmin	]
					}
				]
				, activeNavLink: 'admin/shipping/accounts'
				, secondaryNav: ['Admin', 'Shipping', 'Accounts']
			}
			, {
				name: 'Boxes'
				, exact: true
				, path: 'admin/shipping/boxes/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ PermissionRole.SystemAdmin	]
					}
				]
				, activeNavLink: 'admin/shipping/boxes'
				, secondaryNav: ['Admin', 'Shipping', 'Boxes']
			}
			, {
				name: 'Shipments'
				, exact: true
				, path: 'admin/shipping/shipments/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ 
							PermissionRole.SystemAdmin
							, PermissionRole.Pharmacist 
						]
					}
				]
				, activeNavLink: 'admin/shipping/shipments'
				, secondaryNav: ['Admin', 'Shipping', 'Shipments']
			}
		]
	}

	const users: NavRoute = {
		name: 'Users'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ ...sec.setMinRole(p.Manager) ]
			}
		]
		, children: [
			{
				name: 'User List'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ ...sec.setMinRole(p.Pharmacist) ]
					}
				]
				, exact: true
				, path: 'admin/users/user/list'
				, activeNavLink: 'admin/users/user'
				, secondaryNav: ['Admin', 'Users', 'User List']
			}
			, {
				name: 'Global Audit History'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ ...sec.setMinRole(p.Manager) ]
					}
				]
				, exact: true
				, path: 'admin/users/global-audit-history'
				, activeNavLink: 'admin/users/global-audit-history'
				, secondaryNav: ['Admin', 'Users', 'Global Audit History']
			}
			, {
				name: 'Login History'
				, path: 'admin/users/login-history'
				, exact: true
				, activeNavLink: 'admin/users/login-history'
				, secondaryNav: ['Admin', 'Users', 'Login History']
			}
			, {
				name: 'Custom Fields'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ PermissionRole.SystemAdmin ]
					}
				]
				, path: 'admin/users/custom-fields/list'
				, activeNavLink: 'admin/users/custom-fields'
				, secondaryNav: ['Admin', 'Users', 'Custom Fields']
			}
		]
	} 

	const permissionsInfo: NavRoute = {
		name: 'Permissions Info'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ PermissionRole.SystemAdmin ]
			}
		]
		, children: availableModules.map((key) => (
			{
				name: BusinessAreaEnum.toDescription(Number(key))
				, exact: true
				, path: 'admin/permissions-info/' 
					+ BusinessAreaEnum.toDescription(Number(key)).split(" ").join("-").toLowerCase() 
					+ '/list/' 
					+ key
				, activeNavLink: 'admin/permissions-info/' + BusinessAreaEnum.toDescription(Number(key)).split(" ").join("-").toLowerCase()
				, secondaryNav: ['Admin', 'Permissions Info', BusinessAreaEnum.toDescription(Number(key))]
			}
		))
	};

	const system: NavRoute = {
		name: "System"
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ PermissionRole.SystemAdmin ]
			}
		]
		, children: [
			{
				name: 'Printer Management'
				, path: 'admin/system/printers/list'
				, activeNavLink: 'admin/system/printers'
				, secondaryNav: ['Admin', 'System', 'Printer Management']
			}
			, {
				name: 'System Status'
				, exact: true
				, path: 'admin/system/statuses/list'
				, activeNavLink: 'admin/system/statuses'
				, secondaryNav: ['Admin', 'System', 'System Status']
			}
			, {
				name: 'Verification Settings'
				, exact: true
				, path: 'admin/system/verification-settings'
				, activeNavLink: 'admin/system/verification-settings'
				, secondaryNav: ['Admin', 'System', 'Verification Settings']
			}
			, {
				name: 'Quartz History'
				, exact: true
				, path: 'admin/system/quartz/pcg-history'
				, activeNavLink: 'admin/system/quartz/pcg-history'
				, secondaryNav: ['Admin', 'System', 'Quartz History']
			}
		]
	};	
	//#endregion

	const children : NavRoute[] = [
		dashboard
		, organizations
		, inventoryConfig
		, shipping
		, users
		, permissionsInfo
		, system
	]

	const adminNavItems : NavRoute = {
		name: 'Admin'
		, path: 'admin/dashboard'
		, children: children
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
		, activeNavLink: 'admin/dashboard'
		, secondaryNav: ['Admin', 'Dashboard']
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.Admin)
		, area: 'admin'
		, module: BusinessAreaEnum.Admin
	}

	return adminNavItems;
};
