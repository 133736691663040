import { CodeEditor } from '@acrodata/code-editor';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbDatepickerModule, NgbModalModule, NgbNavModule, NgbTooltipModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";

import { AuthGuard } from "app/core/security/auth.guard";
import { InvChoiceModalComponent } from "app/fulfillment/inv-choice-modal/inv-choice-modal.component";
import { ShipmentListRxModalComponent } from "app/fulfillment/shipment-list-rx-modal/shipment-list-rx-modal.component";
import { RxCommentsModalComponent } from "app/inventory/rx-fulfillment/edit-rx-fulfillment/helpers/comments-modal/comments-modal.component";
import { PatientPhiCardComponent } from "app/inventory/rx-fulfillment/edit-rx-fulfillment/helpers/patient-phi-card/patient-phi-card.component";
import { ShipmentListComponent } from "app/inventory/rx-fulfillment/shipment-list/shipment-list.component";
import { ScanRxComponent } from "app/inventory/scan-rx/scan-rx.component";
import { ScanProductComponent } from "app/shared/scan-product/scan-product.component";
import { AuditHistoryComponent } from "./audit-history/audit-history.component";
import { VerificationSettingsComponent } from './authentication/verification-settings/verification-settings.component';
import { BarcodeScannerStrichComponent } from "./barcode-scanner-strich/barcode-scanner-strich.component";
import { BounceAlertComponent } from "./bounce-alert/bounce-alert.component";
import { CiWorkstationComponent } from "./ci-workstation/ci-workstation.component";
import { SharedCommentsModalComponent } from "./comments-modal/comments-modal.component";
import { ConversationsComponent } from "./conversations/conversations.component";
import { FaCountComponent } from "./fa-num-icon/fa-count/fa-count.component";
import { FaNumIconComponent } from "./fa-num-icon/fa-num-icon.component";
import { EditFacilityComponent } from "./facilities/edit-facility/edit-facility.component";
import { FacilitiesListComponent } from "./facilities/facilities-list/facilities-list.component";
import { FacilityAuditHistoryComponent } from './facilities/facility-audit-history/facility-audit-history.component';
import { CiBusinessAreaComponent } from "./facilities/facility-business-areas/ci-business-area/ci-business-area.component";
import { FaciltyBusinessAreasComponent } from "./facilities/facility-business-areas/facilty-business-areas.component";
import { InventoryBusinessAreaComponent } from "./facilities/facility-business-areas/inventory-business-area/inventory-business-area.component";
import { MetabolicBusinessAreaComponent } from "./facilities/facility-business-areas/metabolic-business-area/metabolic-business-area.component";
import { FacilityCardinalAccountsComponent } from "./facilities/facility-cardinal-accounts/facility-cardinal-accounts.component";
import { InventoriesModule } from "./facilities/facility-inventories/inventories.module";
import { FacilityUserListComponent } from "./facilities/facility-user-list/facility-user-list.component";
import { FormElementsModule } from "./form-elements/form-elements.module";
import { ImageSliderModule } from "./image-slider/image-slider.module";
import { MaterialsModule } from "./material.module";
import { NavigationModule } from "./navigation/navigation.module";
import { EditOrganizationFacilityComponent } from "./organizations/edit-organization-facility/edit-organization-facility.component";
import { AssociatedUsersComponent } from "./organizations/edit-organization/edit-organization-tabs/associated-users/associated-users.component";
import { EditOrganizationComponent } from "./organizations/edit-organization/edit-organization.component";
import { EditOrganizationsModule } from './organizations/edit-organization/edit-organization.module';
import { OrgFacilityInventoriesComponent } from "./organizations/org-facility-inventories/org-facility-inventories.component";
import { OrganizationAccountsComponent } from "./organizations/organization-accounts/organization-accounts.component";
import { OrganizationAuditHistoryComponent } from "./organizations/organization-audit-history/organization-audit-history.component";
import { OrganizationFacilityListComponent } from "./organizations/organization-facility-list/organization-facility-list.component";
import { OrganizationLoginsComponent } from "./organizations/organization-logins/organization-logins.component";
import { OrganizationsListComponent } from "./organizations/organizations-list/organizations-list.component";
import { ProgramsAccountsInfographicComponent } from './organizations/programs-accounts-infographic/programs-accounts-infographic.component';
import { SafePipe } from './pipes/safe.pipe';
import { UtcToLocalPipe } from "./pipes/utcToLocalDate.pipe";
import { PrimeNgModule } from "./primeNg.module";
import { StepperModule } from "./stepper/stepper.module";
import { TableModule } from "./table/table.module";
import { TooltipLabelComponent } from './tooltip-label/tooltip-label.component';
import { TooltipModule } from "./tooltip/tooltip.module";

const routes = [
	{
		path: "audit-history/:id"
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
	}
	, {
		path: "audit-history/:id/:vmType/:auditName"
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
	}
	, {
		path: "ci-repack/admin/settings/audit-history/:id"
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
	}
	, {
        path: "ci-repack/admin/boxes/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "ci-repack/admin/label-printers/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "ci-repack/admin/office-printers/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "ci-repack/admin/zebra-printers/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "ci-repack/admin/plates/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "ci-repack/admin/rework-reasons/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "ci-repack/admin/workstations/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "error-report/admin/report-builder/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "error-report/admin/error-types/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "error-report/admin/permission-levels/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/admin/categories/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/admin/priorities/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/admin/statuses/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/admin/template-responses/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/dashboard-settings/upcoming-features/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/dashboard-settings/support-settings/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "support/tickets/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "heros/admin/ordering-options/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "icq/admin/sections/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "icq/admin/issues/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "icq/admin/questions/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
        path: "icq/admin/settings/audit-history/:id"
        , component: AuditHistoryComponent
        , canActivate: [ AuthGuard ]
        , canLoad: [ AuthGuard ]
    }
	, {
		path: "ndcs/fda/audit-history/:id"
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
	}
	, {
		path: "ba/admin/fiscal-years/audit-history/:id"
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
	}
];

/**
 * SharedModule exists to make commonly used components, directives,
 * and pipes available for use in the templates of components throughout
 * the application.
 */
@NgModule({
	declarations: [
		AuditHistoryComponent
		, BounceAlertComponent
		, RxCommentsModalComponent
		, SharedCommentsModalComponent
		, FaCountComponent
		, FaNumIconComponent
		, InvChoiceModalComponent
		, PatientPhiCardComponent
		, ScanProductComponent
		, ScanRxComponent
		, ShipmentListComponent
		, ShipmentListRxModalComponent
		, FacilitiesListComponent
		, EditFacilityComponent
		, FacilityCardinalAccountsComponent
		, FacilityAuditHistoryComponent
		, FacilityUserListComponent
		, OrganizationsListComponent
		, EditOrganizationComponent
		, OrganizationFacilityListComponent
		, AssociatedUsersComponent
		, EditOrganizationFacilityComponent
		, OrgFacilityInventoriesComponent
		, OrganizationAuditHistoryComponent
		, OrganizationAccountsComponent
		, OrganizationLoginsComponent
		, SafePipe
		, UtcToLocalPipe
		, CiWorkstationComponent
		, CiBusinessAreaComponent
		, InventoryBusinessAreaComponent
		, MetabolicBusinessAreaComponent
		, ConversationsComponent
		, FaciltyBusinessAreasComponent	
		, VerificationSettingsComponent
		, BarcodeScannerStrichComponent
		, TooltipLabelComponent
		, ProgramsAccountsInfographicComponent
	]
	, imports: [
		CdkStepperModule
		, CdkTableModule
		, CommonModule
		, FontAwesomeModule
		, FormElementsModule
		, FormsModule
		, MaterialsModule
		, NavigationModule
		, NgbDatepickerModule
		, NgbModalModule
		, NgbNavModule
		, NgbTooltipModule
		, NgbTypeaheadModule
		, NgSelectModule
		, ReactiveFormsModule
		, RouterModule.forChild(routes)
		, StepperModule
		, TableModule
		, TooltipModule
		, UiSwitchModule
		, ImageSliderModule
		, DragDropModule
		// The architecture for these modules are bit
		// messy. We need to be better about breaking groups
		// of components out into their modules.
		, InventoriesModule
		, EditOrganizationsModule
		, CodeEditor
		, PrimeNgModule
	]
	, exports: [
		AuditHistoryComponent
		, BounceAlertComponent
		, CdkStepperModule
		, CdkTableModule
		, RxCommentsModalComponent
		, SharedCommentsModalComponent
		, CommonModule
		, FaCountComponent
		, FaNumIconComponent
		, FontAwesomeModule
		, FormElementsModule
		, FormsModule
		, InvChoiceModalComponent
		, MaterialsModule
		, NavigationModule
		, NgbDatepickerModule
		, NgbModalModule
		, NgbNavModule
		, NgbTooltipModule
		, NgbTypeaheadModule
		, NgSelectModule
		, PatientPhiCardComponent
		, ReactiveFormsModule
		, ScanProductComponent
		, ScanRxComponent
		, ShipmentListComponent
		, ShipmentListRxModalComponent
		, StepperModule
		, TableModule
		, TooltipModule
		, UiSwitchModule
		, FacilitiesListComponent
		, EditFacilityComponent
		, FacilityCardinalAccountsComponent
		, FacilityAuditHistoryComponent
		, FacilityUserListComponent
		, OrganizationsListComponent
		, EditOrganizationComponent
		, OrganizationFacilityListComponent
		, EditOrganizationFacilityComponent
		, OrgFacilityInventoriesComponent
		, OrganizationAuditHistoryComponent
		, OrganizationAccountsComponent
		, OrganizationLoginsComponent
		, SafePipe
		, UtcToLocalPipe
		, CiWorkstationComponent
		, CiBusinessAreaComponent
		, InventoryBusinessAreaComponent
		, MetabolicBusinessAreaComponent
		, ImageSliderModule
		, ConversationsComponent
		, FaciltyBusinessAreasComponent
		, DragDropModule
		, VerificationSettingsComponent
		, BarcodeScannerStrichComponent
		, CodeEditor
		, TooltipLabelComponent
		, PrimeNgModule
	]
})
export class SharedModule {}
