import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { EditRxFulfillmentComponent } from 'app/inventory/rx-fulfillment/edit-rx-fulfillment/edit-rx-fulfillment.component'
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { ShipmentCommentVm } from 'app/shared/generated/Inventory/Models/Shipping/ShipmentCommentVm';

@Component({
    selector: 'pcg-shipment-comments-modal',
    templateUrl: './comments-modal.component.html',
    styleUrls: ['./comments-modal.component.scss'],
    standalone: false
})
export class RxCommentsModalComponent implements OnInit {

	@ViewChild('pfsFormSelect', {static: false}) pfsFormSelect: PcgSelectComponent;
	@ViewChild(EditRxFulfillmentComponent, {static: true}) editRxFulfillment: EditRxFulfillmentComponent;

	@Input() shipmentId: number;
	@Input() modal: any;
	@Input() isUser: boolean = false;

	inventoryId: number;
	user: UserJsVm;
	formGroup = ShipmentCommentVm.Form;
	countPfs: number;
	pfsFormIdLink: string;
	formId: string;

	constructor(
		private sec: SecurityService
		, private route: ActivatedRoute
		, private httpClient: HttpClient
	) { }

	ngOnInit() {
		this.sec.user$.subscribe(user => { this.user = user; });
		this.inventoryId = Number(this.route.snapshot.params.inventoryId);
		this.getPfsForm();
	}

	getPfsForm() {
		this.httpClient.get(
			`api/Inventory/${this.isUser ? 'RxFulfillmentUser' : 'Shipping'}/GetPfsForm?id=${this.shipmentId}`
		).subscribe((msg: SystemMessage) => {
			if (msg.model.pfsFormId != null) {
				this.formGroup.controls.pfsFormID.setValue(msg.model.pfsFormId);
				this.pfsFormIdLink = msg.model.pfsUrl;
				this.formId = msg.model.pfsFormId;
			}
			this.countPfs = msg.model.countPfsFormIds;
		});
	}
}
