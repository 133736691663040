<div 
    pcgClickOutside 
    [ngbTooltip]="quickNavService.showContent() ? null : tooltip"
    class="border-0"
    (clickOutside)="quickNavService.preventCloseContent() ? false : quickNavService.closeContent();"
>
    
    <!-- Icon -->
    <div 
        class="circle" 
        (click)="quickNavService.toggleContent();"
    >
        <fa-icon [icon]="icon"></fa-icon>
    </div>
    
    <!-- Projected Content -->
    @if(quickNavService.showContent()) {
        <div 
            class="content" 
            [ngClass]="navService.isMobile() == true ? 'mobile' : 'notMobile'"
        >
            <ng-content></ng-content>
        </div>
    }
</div>