import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightArrowLeft, faCheck, faCircle, faExternalLinkAlt, faSortUp, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, tap } from 'rxjs';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { OrganizationAccountRequestVm } from 'app/shared/generated/Administration/Models/Organizations/OrganizationAccountRequestVm';
import { GenericFilesVm } from 'app/shared/generated/Models/GenericFilesVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { NotificationService } from 'app/notification.service';
import { NavNotificationService } from 'app/shared/navigation/nav-notification.service';

@Component({
    selector: 'pcg-organization-account-update',
    templateUrl: './organization-account-update.component.html',
    styleUrls: ['./organization-account-update.component.scss'],
    standalone: false
})
export class OrganizationAccountUpdateComponent implements OnInit {
    notificationService = inject(NotificationService);
    ns = inject(NavNotificationService);

    @Output() tm = new EventEmitter();
    @Input() id: number;
    // Will be true if this component is rendered via the top nav icon. Used to conditionally hide 'X' close icon
    @Input() calledFromNav: boolean = false;

    faCircle = faCircle;
    faArrowRightArrowLeft = faArrowRightArrowLeft;
    faLink = faExternalLinkAlt;
    faArrow = faSortUp
    faIconName = { faCheck, faTimes, faArrowLeft };

    model$: Observable<OrganizationAccountRequestVm>;
    form = OrganizationAccountRequestVm.Form;

    isAccountList = false;
    isAccountApproval = false;
    clearFileClick = false;
    hasFile = false;
    expDateRequired: boolean = false;
    organizationFile: GenericFilesVm[] = [];
    maxFiles = 1;

    readOnly : boolean = false;
    
    constructor(
        private http: HttpClient
        , public modal: NgbActiveModal
        , public router: Router
        , public ms: SystemMessageService
    ) { }

    ngOnInit(): void {
        this.model$ = this.http.get<OrganizationAccountRequestVm>(`api/Administration/OrganizationAccountRequest/GetOrganizationAccountRequest?id=${this.id}`)
            .pipe(tap((model: OrganizationAccountRequestVm) => {
                this.form.patchValue(model);
                // Assign boolean to differentiate between account approval and update
                this.isAccountApproval = model.oldFacilityAccountName.facilityAccountNameId === 0;
                
                this.resizeModal();
                this.disableOldFormGroup();
                this.hasFile = FormatHelperService.GetIsNullyOrWhitespace(this.form.controls.newUploadFilePath.value) 
                    ? false 
                    : true;
                this.expDateRequired = model.isNotification;
                this.setExpDateRequired();
                if (this.readOnly) { this.form.disable(); }
              })
        );
        this.isAccountList = this.router.url.includes('account-request-queue');
    }
    
    onSubmit() {
        if (this.form.valid) {
            const form = this.form.value;
            this.http.post("api/Administration/OrganizationAccountRequest/ApproveDenyRequest", form)
                .subscribe((resp: SystemMessage) => {
                    if (!resp.isSuccessful) { this.notificationService.showMessage(resp.message, resp.messageClass)}
                    if (resp.isSuccessful) {
                        this.form.patchValue(resp.model);
                        this.modal.close();
                        this.ns.getHeaderCounts();
                    }
                }, (err) => { console.log(err) }
            );
        } else { validateForm(this.form); }
    }

    disableOldFormGroup() {
        this.form.get('oldFacilityAccountName').disable();
        this.form.get('oldAccountNumber').disable();
        this.form.get('oldExpirationDate').disable();
        this.form.get('oldNotes').disable();
        this.form.get('oldIsActive').disable();
        this.form.get('oldIsGracePeriod').disable();
        this.form.get('oldUploadFilePath').disable();
    }

    clearFile() {
        this.clearFileClick = true;
        this.form.get('newUploadFilePath').patchValue(null);
        this.hasFile = false;
    }

    submitButton(status: boolean) { this.form.get('submitButton').patchValue(status); }

    /** Emits event to toggle between list and update request. */
    toggleModal() {
        this.tm.emit();
        this.modal.update({ size: 'l' });
    }

    // Resizes modal depending on what type of organization account request - approval/update
    resizeModal() { this.isAccountApproval ? this.modal.update({ size: 'l' }) : this.modal.update({ size: 'xl' }); }
    isEmpty = (str: string) => FormatHelperService.GetIsNullyOrWhitespace(str)

    setExpDateRequired() {
        if (this.expDateRequired ) { 
            this.form.controls.newExpirationDate.enable();
            this.form.controls.newExpirationDate.addValidators([Validators.required]); 
        } else { this.form.controls.newExpirationDate.removeValidators([Validators.required]); }

        this.form.controls.newExpirationDate.updateValueAndValidity();
    }
}
