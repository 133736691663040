import { computed, inject, Injectable, signal } from "@angular/core";
import { UntilDestroy } from '@ngneat/until-destroy';

import { SecurityService } from "app/core/security/security.service";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { HeaderVm } from "../generated/Models/HeaderVm";
import { HttpClient } from "@angular/common/http";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NotificationNavComponent } from "./notification-nav/notification-nav.component";

/**
* The `NavNotificationService` class is responsible for:

* - Maintaining current state of the notification counts
* - Fetching notification counts from the server
*/
@UntilDestroy()
@Injectable({ providedIn: "root" })
export class NavNotificationService {
    sec = inject(SecurityService);
    httpClient = inject(HttpClient);
    modalService = inject(NgbModal);
    
    
    notificationCount = computed(() => {
        return this.reconCount()
			+ [
				this.ciInvoicesCount(),
				this.icqCount()
			].filter(count => count > 0).length;
    });

	reconCount = signal<number>(0);
	ciInvoicesCount = signal<number>(0);
	icqCount = signal<number>(0);
	step1Count = signal<number>(0);
	step2Count = signal<number>(0);
	step3Count = signal<number>(0);
    accountRequestCount = signal<number>(0);

	fulfillmentNavAccess = signal<boolean>(false);
	invoiceNavAccess = signal<boolean>(false);
	accountRequestAccess = signal<boolean>(false);
	reconNavAccess = signal<boolean>(false);
	icqAccess = signal<boolean>(false);
	isCiUser = signal<boolean>(false);
	hasCiWorkstation = signal<boolean>(false);

    notificationModal: NgbModalRef;


    getHeaderCounts() {
		this.setAccessFlags();
		const step1: number = +sessionStorage.getItem("step1Count");
		const step2: number = +sessionStorage.getItem("step2Count");
		const step3: number = +sessionStorage.getItem("step3Count");
		const opened = sessionStorage.getItem("notificationModalOpened");

		if (
			this.fulfillmentNavAccess() 
			|| this.reconNavAccess() 
			|| this.invoiceNavAccess() 
			|| this.icqAccess()
		) { this.fetchHeaderCountsFromServer(opened, step1, step2, step3); } 
		else { this.setStepCounts(step1, step2, step3); }
	}

	setAccessFlags() {
		this.fulfillmentNavAccess.set(this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		));

        this.reconNavAccess.set(this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		));
		this.invoiceNavAccess.set(this.sec.hasModuleAccess(
			[ BusinessAreaEnum.CI, BusinessAreaEnum.BA, BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		));
		this.accountRequestAccess.set(this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		));
		this.icqAccess.set(this.sec.hasModuleAccess(
			[ BusinessAreaEnum.ICQ ]
			, SecurityService.setMinRole(PermissionRole.User)
		));
	}

	fetchHeaderCountsFromServer(
		opened: string
		, step1: number
		, step2: number
		, step3: number
	) {
		this.httpClient.get(`api/Header/GetHeaderCounts
			?reconAccess=${this.reconNavAccess()}
			&rxAccess=${this.fulfillmentNavAccess()}
			&invoiceAccess=${this.invoiceNavAccess()}
			&icqAccess=${this.icqAccess()}
            &accountRequestAccess=${this.accountRequestAccess()}`
		).subscribe((vm: HeaderVm) => {
			this.updateSessionStorage(vm);
			this.updateCounts(vm);
			this.checkAndOpenNotificationModal(opened);
		});

		if (!this.fulfillmentNavAccess) { this.setStepCounts(step1, step2, step3); }
	}

	updateSessionStorage(vm: HeaderVm) {
		sessionStorage.setItem("step1Count", vm.fulfillmentStep1ProductPrepCount.toString());
		sessionStorage.setItem("step2Count", vm.fulfillmentStep2ShippingCount.toString());
		sessionStorage.setItem("step3Count", vm.fulfillmentStep3QCCount.toString());
        sessionStorage.setItem("accountRequestCount", vm.accountRequestCount.toString());
	}

	updateCounts(vm: HeaderVm) {
		this.step1Count.set(vm.fulfillmentStep1ProductPrepCount);
		this.step2Count.set(vm.fulfillmentStep2ShippingCount);
		this.step3Count.set(vm.fulfillmentStep3QCCount);
        this.accountRequestCount.set(vm.accountRequestCount);
        
        this.reconCount.set(vm.reconciliationCount);
        this.ciInvoicesCount.set(vm.ciInvoicesCount);
        this.icqCount.set(vm.icqNewSurveyCount);
	}

	checkAndOpenNotificationModal(opened: string) {
		if (this.notificationCount() > 0) {
			if (
				opened !== "true" 
				&& this.sec.getUser()?.receiveReconciliationNotifications 
				&& (
					this.isCiUser() !== true 
					|| this.hasCiWorkstation() === true
				)
			) { setTimeout(() => { this.openNotificationModal(); }, 100); }
		}
		sessionStorage.setItem("notificationModalOpened", "true");
	}

	setStepCounts(
		step1: number
		, step2: number
		, step3: number
	) {
		this.step1Count.set(step1);
		this.step2Count.set(step2);
		this.step3Count.set(step3);
	}

    openNotificationModal() {
		this.notificationModal = this.modalService.open(NotificationNavComponent, { animation: false });
		this.notificationModal.componentInstance.modalRef = this.notificationModal;
	}


}
