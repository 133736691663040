import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { HttpClient } from "@angular/common/http";
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { faComments } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faLevelDownAlt, faTimes, faTruck } from "@fortawesome/pro-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { SelectItem } from 'app/shared/models/select-item';
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { GlobalVariablesService } from "app/services/global-variables.service";
import { FaIconName } from "app/shared/fa-num-icon/fa-icon-name/fa-icon-name";
import { FaIconPosition, FaPositionUnits } from "app/shared/fa-num-icon/fa-icon-position/fa-icon-position";
import { FaNumColor } from "app/shared/fa-num-icon/fa-num-color/fa-num-color.enum";
import { EditShipmentVm } from "app/shared/generated/Inventory/Models/Shipping/EditShipmentVm";
import { FormatHelperService } from "app/shared/helpers/format-helper.service";
import { StepperComponent } from "app/shared/stepper/stepper.component";
import { RxFulfillmentPackagesComponent } from "./helpers/packages/rx-fulfillment-packages.component";
import { RxFulfillmentPrescriptionsComponent } from "./helpers/prescriptions/rx-fulfillment-prescriptions.component";
import { QcCheckComponent } from "./helpers/qc-check/qc-check.component";
import { SubmitComponent } from "./helpers/submit/submit.component";
import { ConfirmationModalComponent } from "app/shared/form-elements/components/confirmation-modal/confirmation-modal.component";
import { NotificationService } from "app/notification.service";
import { UserJsVm } from "app/shared/generated/Administration/Models/Users/UserJsVm";

@Component({
    selector: "pcg-edit-rx-fulfillment",
    templateUrl: "./edit-rx-fulfillment.component.html",
    styleUrls: ["./edit-rx-fulfillment.component.scss"],
    standalone: false
})
export class EditRxFulfillmentComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {

	@ViewChild("stepper", { static: true }) stepper: StepperComponent;
	@ViewChild("packages") packages: RxFulfillmentPackagesComponent;
	@ViewChild("prescriptions") prescriptions: RxFulfillmentPrescriptionsComponent;
	@ViewChild("qcCheck") qcCheck: QcCheckComponent;
	@ViewChild("submit") submit: SubmitComponent;
	@ViewChild("printerSelect", { static: true }) printerSelect: PcgSelectComponent;

	// IDs
	inventoryId: number;
	shipmentId: number;
	currentStep: number;
	selectedStep: number;
	commentsCount = 0;
	shipmentPrinterId: number;
	userId: number;

	isComplete: boolean;
	isNotActive: boolean;
	isFacility: boolean;
	canEditDeliveryType = false;
	canEdit: boolean;
	canEditQc: boolean;
	auditHistoryAccess: boolean;
	isPcg: boolean;
	isUser: boolean = false;
	userSubmitted: boolean = false;

	inventorySiteName: string;
	dataSource = "api/Inventory/Shipping/GetShipment/";
	backLink = "/pharmacy/fulfillment/list";
    facilityName: string;
	
	printerSelectItem: SelectItem;
    user: UserJsVm;

	faTruck = faTruck;
	faLevelDownAlt = faLevelDownAlt;
	faCheck = faCheck;
	faTimes = faTimes;
	faComments = faComments;
	commentPosition: FaIconPosition = new FaIconPosition(
		-10
		, 10
		, null
		, null
		, FaPositionUnits.pixels
	);
	faNumColor: typeof FaNumColor = FaNumColor;
	faIconName: FaIconName = new FaIconName();

	constructor(
		title: Title
		, private route: ActivatedRoute
		, private httpClient: HttpClient
		, private sec: SecurityService
		, public modalService: NgbModal
		, private ms: SystemMessageService
		, private router: Router
		, private gvs: GlobalVariablesService
		, private cdRef: ChangeDetectorRef
        , private notificationService: NotificationService
	) {
		this.auditHistoryAccess = sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		);
		this.canEdit = sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, [ PermissionRole.Technician, PermissionRole.Pharmacist ]
			, false
		);
		this.canEditQc = sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
        this.user = this.sec.getUser();
        this.userId = this.user?.id;
		this.isUser = this.user?.isRxUser;
		if (this.isUser) {
			this.dataSource = "api/Inventory/RxFulfillmentUser/GetShipment/";
			this.backLink = "/pharmacy/fulfillment/list";
            title.setTitle("Edit Refill Request");
		} else { 
            this.backLink = "/pharmacy/inventories/fulfillment/list/" + this.inventoryId; 
            title.setTitle("Edit Rx Fulfillment");
        }
	}

	//This host listener makes it so that the post fires even when the browser or tab closes
	@HostListener("window:beforeunload", ["$event"])
	beforeunloadHandler(event) { this.closeUserFromFulfillment(this.userId); }

	ngOnInit() {
		this.shipmentId = parseInt(this.route.snapshot.paramMap.get("id"), 10);
		this.inventoryId = parseInt(this.route.snapshot.paramMap.get("inventoryId"), 10);
		this.isPcg = this.sec.getUser().email?.includes("paulconsulting");		
	}

	ngAfterViewInit() { this.getDetails(); this.cdRef.detectChanges(); }
	ngAfterContentChecked() { this.cdRef.detectChanges(); }

	reloadContent(event: StepperSelectionEvent) {
		this.selectedStep = event.selectedIndex;
		if (event.selectedIndex === 2) {
			this.qcCheck.rxNumberSelect.clear();
			this.qcCheck.rxNumberSelect.getData();
			this.qcCheck.rxNumberSelect.triggerChanged();
			this.qcCheck.focusRx();
			this.getPrinter();
		}
		if (event.selectedIndex === 3) { this.getPrinter(); }
	}

	openCommentsModal(
		modal: any
		, shipmentId: number
	) {
		this.shipmentId = shipmentId;
		this.modalService.open(
			modal
			, {
				animation: false
				, beforeDismiss: () => {
					this.getDetails();
					return true;
				}
			}
		);
	}

	getDetails() {
		this.httpClient.get(this.dataSource + this.shipmentId)
			.subscribe((model: EditShipmentVm) => {
				if (model !== null) {					
					this.currentStep = model.currentStep;
					this.commentsCount = model.commentsCount;
					this.setCurrentStep();
					this.isComplete = this.currentStep === 4;
					if (this.submit) { 
						this.submit.isSuccessful = this.isComplete;
						this.submit.hideShowSelection(); 
					}
					this.isFacility = model.isFacility;
					this.userSubmitted = model.userSubmitted;
					this.canEditDeliveryType = model.canEditDeliveryType;
					this.inventorySiteName = model.inventoryName;
					this.gvs.pharmInvName.set(this.inventorySiteName);
					this.isNotActive = model.isNotActive;
                    this.facilityName = model.facilityName;
					if (
                        !FormatHelperService.GetIsNullyOrWhitespace(model.openUsersWarningMessage)
                        && !this.isUser
                    ) { this.ms.setSystemMessage(model.openUsersWarningMessage, "warning"); }
				}
			}
		);
	}

    setActivation() {
        const modal = this.modalService.open(ConfirmationModalComponent);
        modal.componentInstance.modalRef = modal;
        modal.componentInstance.confirmationMessage = 
		`<p class="text-center">Are you sure you want to ${this.isNotActive ? "activate" : "deactivate"} this fulfillment?</p>`;
		if(!this.isNotActive) {
            modal.componentInstance.confirmationMessage +=`<p class="text-center">This will:<br>
            <ul>
                <li>Void all package labels that have not yet been shipped</li>
                <li>Remove all Rx's from the fulfillment</li>
                <li>Return all products to the inventory</li>
            </ul>
            </p>`;
        }

        // If user continues:
        modal.result.then((emittedValue) => {
            if (emittedValue) { 
				// Activate fulfillment
                if (this.isNotActive) {
                    this.httpClient.get(`api/Inventory/Shipping/SetFulfillmentActivation?id=${this.shipmentId}`)
                        .subscribe((msg: SystemMessage) => {
                            this.notificationService.showMessage(msg.message, msg.messageClass);
                            this.isNotActive = msg.value;
                        });
                } 
                // Deactivate fulfillment
                else {
                    this.httpClient.get(`api/Inventory/Shipping/DeactivateFulfillment?id=${this.shipmentId}`)
                        .subscribe((msg: SystemMessage) => {
                            this.notificationService.showMessage(msg.message, msg.messageClass);
                            if(msg.isSuccessful) {
                                this.isNotActive = msg.value;
                                // Page reload to reset stepper and child components
                                window.location.reload();
                            }
                        });
                }
			} 
        }).catch(err => {
            // Prevents Uncaught (in promise) error when user  
            // closes modal from clicking outside modal. 
        });
	}

	undoQcCheck() {
		this.currentStep = 2;
		this.setCurrentStep();
	}

	setCurrentStep() {
		// set the current step to Done if its set to 4, other wise set to the current step
		if (!this.isUser) { setTimeout(() => { this.stepper.selectedIndex = this.currentStep !== 4 ? this.currentStep : 3; }, 1); }		
	}

	completeStep1() {
		if (
			this.isComplete 
			|| this.qcCheck?.qcCheckComplete
		) { this.stepper.next(); } 
		else if (this.stepper.selectedIndex === 0) {
			this.currentStep = 1;
			this.setCurrentStep();
			this.qcCheck.getQcCheck();
			this.qcCheck.rxNumberSelect.getData();
		}
	}

	uncompleteStep1() { if (this.currentStep > 0) { this.currentStep = 0; } }

	// Move on to step 3 after continue button is hit in step 2
	completeStep2() {
		if (
			!this.canEditQc 
			&& !this.qcCheck?.qcCheckComplete
		) { this.router.navigateByUrl("/pharmacy/fulfillment/list"); }
		if (
			this.isComplete 
			|| this.qcCheck?.qcCheckComplete
		) { this.stepper.next(); } 
		else if (this.stepper.selectedIndex === 1) {
			this.currentStep = 2;
			this.setCurrentStep();
		}
		this.qcCheck.reloadPage();
	}

	closeUserFromFulfillment(userId: number) {
		return this.httpClient.post(`api/Account/CloseUserFromFulfillment/?fulfillmentId=${this.shipmentId}&userId=${userId}`, {}).subscribe();
	}

	// Move on to step 4 after continue button is hit in step 3
	completeStep3() {
		if (this.isComplete) { this.stepper.next(); }
		if (
			this.stepper.selectedIndex === 2 
			&& this.qcCheck?.qcCheckComplete
		) {
			if (this.currentStep < 3) { this.currentStep = 3; }
			this.setCurrentStep();
			this.submit.submit();
		}
	}

	submitShipment() {
		this.currentStep = 4;
		this.isComplete = true;
	}

	// Keeps the packages table on step 4 in sync with changes from step 2
	reloadStep4() { if (this.submit) { this.submit.table.ajaxReload(); } }

	changeDeliveryType() {
		const deliveryType = this.isFacility 
			? "Home Delivery" 
			: "CI/CHD Delivery";
		if (confirm("Are you sure you want to change the delivery type to " + deliveryType + "?")) {
			this.httpClient
				.get(`api/Inventory/Shipping/ChangeShipmentDeliveryType/?shipmentId=${this.shipmentId}&isFacility=${!this.isFacility}`)
				.subscribe((msg: SystemMessage) => {
					this.ms.setSystemMessage(msg.message, msg.messageClass);
					if (msg.isSuccessful) { this.isFacility = !this.isFacility; }
				}
			);
		}
	}

	savePrinter() {
		if (this.printerSelect.value !== null) {
			if (this.printerSelect.additionalData === 1) { sessionStorage.setItem("fedexPrinter", this.printerSelect.value); } 
			else { sessionStorage.setItem("upsPrinter", this.printerSelect.value); }
		}
	}

	getPrinter() {
		const fedexPrinter: number = +sessionStorage.getItem("fedexPrinter");
		const upsPrinter: number = +sessionStorage.getItem("upsPrinter");
		this.shipmentPrinterId = null;
		this.httpClient.get(`api/Select/ShipmentPrinters?shipmentId=${this.shipmentId}`)
			.subscribe((items: SelectItem[]) => {
				this.printerSelect.clear();
				this.printerSelect.sourceItems = this.printerSelect.items = items;
				if (
					this.printerSelect.items.find((o) => o.additionalData === 1) !== undefined
					&& this.printerSelect.items.find((o) => o.value === fedexPrinter) !== undefined
				) { this.setPrinter(fedexPrinter); } 
				else if (
					this.printerSelect.items.find((o) => o.additionalData === 2) !== undefined
					&& this.printerSelect.items.find((o) => o.value === upsPrinter) !== undefined
				) { this.setPrinter(upsPrinter); }
			}
		);
	}

	setPrinter(id: number) {
		if (this.printerSelect.items.find((o) => o.value === id) !== undefined) { this.printerSelect.value = id; }
	}

	// Open packing slip in new window
	getPackingSlip() { 
		localStorage.setItem("isNavFixed", "false");
		window.open(`/pharmacy/inventories/fulfillment/packing-slip/${this.shipmentId}`, "_blank"); 
	}

	testZebraApi() {
		this.httpClient.get(`api/Zebra/ZebraTest`)
			.subscribe((sm: SystemMessage) => { this.ms.setSystemMessage(sm.message, sm.messageClass); }
		);
	}

	ngOnDestroy() { this.closeUserFromFulfillment(this.userId); }
}
