<pcg-page-title 
	[primaryTitle]='formGroup.value.facilityName' 
	[secondaryTitles]='[formGroup.value.facilityIdentifier]'
	[backBtnLink]='backBtnLink' 
	[backBtnTooltip]='"Return to " + backBtnTooltip + " Facility List"'
	[backBtnPlacement]="backBtnPlacement"
></pcg-page-title>

<mat-tab-group 
	*ngIf="model$ | async" 
	pcgTabs 
	mat-stretch-tabs='false' 
	mat-align-tabs='start' 
	animationDuration='200ms' 
	[preserveContent]='true'
>
	<mat-tab label="Details">
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<form 
					[formGroup]="formGroup" 
					class='p-0' 
					(ngSubmit)="submitForms();"
				>
					<pcg-page-info *ngIf='model.facilityId === 0'>
						In order to create a Facility you will need to enter the following information.
					</pcg-page-info>

					<div class="mx-0">
						<input type='hidden' formControlName='facilityId' />
						<input type='hidden' formControlName='countyName' />
						<input type='hidden' formControlName="organizationId" />

						<div class="row">
							<pcg-control-group 
								class="col-md-4" 
								label="Facility Name"
							>
								<input 
									pcgControlRef 
									formControlName="facilityName" 
									class="form-control" 
									type="text"
									maxlength="50" 
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-2" 
								label="Identifier"
								labelTooltip='The facility identifier is the commercial facility store number OR the public facility unique identifier.'
							>
								<input 
									pcgControlRef 
									formControlName="facilityIdentifier" 
									class="form-control"
									type="text" 
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-2" 
								label="DAU Code" 
								labelTooltip='This field is used to match this Facility in HOMER with the record for this Facility in HMS. The DAU is a 
									6-character code that is used for DOH facilities throughout the State.'
								>
								<input 
									pcgControlRef 
									formControlName="dauCode" 
									class="form-control" 
									type="text"
									maxlength="6" 
								/>
							</pcg-control-group>

							@if (formGroup.get('facilityType')?.value) {
								<pcg-control-group 
								class="col-md-2" 
								label="Facility Type" 
								>
								<input 
									pcgControlRef 
									formControlName="facilityType" 
									class="form-control" 
									type="text"
									
								/>
								</pcg-control-group>
							}

							<div 
								*ngIf="canDeactivate" 
								class="flex-fill d-flex align-items-center justify-content-end" 
								id="activation"
							>
								<div class="activate d-flex flex-column">
									@if (!model.isActive) {
										<span
										class="text-danger">
										This Facility is deactivated.
										</span>
									}

									<a 
										*ngIf="
											canEdit 
											&& model.facilityId !== 0
										" 
										[class.btn-danger]="model.isActive"
										[class.btn-save]="!model.isActive" 
										class="btn btn-md"
										(click)="
											activateDeactivate(); 
											model.isActive = !model.isActive;
										"
									>{{ model.isActive ? 'Deactivate' : 'Reactivate' }}</a>
									<input 
										*ngIf="model.facilityId === 0" 
										type="hidden"
										formControlName="isActive" 
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div 
								*ngIf="formGroup.value.organizationId != null" 
								class="col-md-4"
							>
								<span class="flex-column">
									<label class="for">Organization</label>
									<span>{{ model?.organizationName }}</span>
								</span>
								<div
									class="btn btn-outline-secondary toggle text-center ml-4 col-md-2"
									[routerLink]="'/admin/organizations/orgs/edit/' + formGroup.value.organizationId"
									ngbTooltip="Navigate to Organization"
								>
									<fa-icon [icon]="faIcon.faExternalLink"></fa-icon>
								</div>
							</div>
	
							<pcg-control-group 
								class="col-md-2" 
								label="Phone"
							>
								<input 
									pcgPhoneExt 
									pcgControlRef 
									class='form-control' 
									type='text'
									formControlName="phoneNumber" 
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-2" 
								label="ICQ Participant"
							>
                                <ui-switch 
                                    pcgControlRef 
                                    #isIcqParticipant 
                                    color='#007bff'
                                    defaultBgColor='#EEE' 
                                    class='ml-2 mt-2'
                                    size='small' 
                                    formControlName='isIcqParticipant'
                                ></ui-switch>
							</pcg-control-group>
						</div>

						<div class="row mb-4" >
							<div class="col-md-2">
								<label class="for">Medical Director</label>
								<span [ngStyle]="{'color': hasMedicalDirector() ? 'black' : 'red'}">{{ getMedicalDirector() }}</span>
							</div>
							<div 
								*ngIf="model.medicalDirectorId != null" 
								class="col-md-2"
							>
								<label class="for">License</label>
								{{ model?.medicalDirectorLicense }}
							</div>
							<div 
								*ngIf="model.medicalDirectorId != null" 
								class="col-md-2"
							>
								<label class="for">Exp Date</label>
								{{ model?.medicalDirectorExpDate }}
							</div>
						</div>
					</div>

					<div class="mx-0">
						<h3>Address</h3>
						<hr />
						<div class="row">
							<pcg-control-group 
								class="col-md-8" 
								label="Address"
								labelTooltip="When searching apartment complexes be sure to use Apt after the street number and name to see the list of 
								apartment numbers. For example, 2000 N Meridian Rd Apt will bring up a list of the apartments for that complex."
							>
								<pcg-smarty 
									pcgControlRef 
									formControlName="smartyAddress" 
									[showAllStates]="false"
									[canManualEntry]="canManualEntry" 
									[getFullZip]="true"
									(change)='updateShippingInfo()' 
									[countyApi]="api"
								></pcg-smarty>
							</pcg-control-group>

							<div class="col-md-2 d-flex flex-column">
								<label class="for">County</label>
								<span>{{ this.formGroup?.controls?.countyName?.value }}</span>
							</div>

							<pcg-control-group 
								class="col-md-2" 
								label="GLN" 
								labelTooltip="Global Location Number"
							>
								<input 
									pcgControlRef 
									class='form-control' 
									type='text' 
									autocomplete='off'
									pcgNoSpecialChars='noSpecialChars' 
									formControlName='gln' 
								/>
							</pcg-control-group>
						</div>
					</div>

					<div class="mx-0">
						<h3>Shipping Address</h3>
						<hr />

						<div class="row">
							<pcg-control-group 
								class="col-md-4" 
								label="Shipping Name"
							>
								<input 
									[hidden]="shipAddressDisabled" 
									pcgControlRef
									formControlName="shipFacilityName" 
									class="form-control" 
									type="text" 
								/>
								<span [hidden]="!shipAddressDisabled">
									{{ this.formGroup?.controls?.shipFacilityName?.value }}
								</span>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-6" 
								label="Address"
							>
								<pcg-smarty 
									pcgControlRef 
									formControlName="shipSmartyAddress"
									[showAllStates]="false" 
									[hidden]="shipAddressDisabled"
									[canManualEntry]="canManualEntry" 
									[countyApi]="api"
								></pcg-smarty>
								<span [hidden]="!shipAddressDisabled">
									{{ this.formGroup?.controls?.shipSmartyAddress?.value?.formattedAddress }}
								</span>
							</pcg-control-group>

							<pcg-control-group 
								class="col-auto form-check-label" 
								label="Is Same?"
								labelTooltip="Verification that facility address is same as shipping address."
							>
								<ui-switch 
									pcgControlRef 
									#isSameAddress 
									color='#007bff'
									(change)="updateShippingInfo()" 
									defaultBgColor='#EEE' 
									class='ml-2 mt-2'
									size='small' 
									formControlName='isSameAddress'
								></ui-switch>
							</pcg-control-group>

							<pcg-control-group 
								class="col-auto form-check-label" 
								label="Is Validated?"
							>
								<ui-switch 
									pcgControlRef 
									#isShipAddressValidated 
									color='#007bff'
									defaultBgColor='#EEE' 
									class='ml-2 mt-2' 
									size='small'
									formControlName='isShipAddressValidated'
								></ui-switch>
							</pcg-control-group>
						</div>
					</div>

					<div class='bottom-buttons'>
						<button 
							*ngIf='canEdit'
							[disabled]="id === 0" 
							class='btn btn-save btn-lg' 
							(click)="validateCountyId()"
						>{{ id === 0 ? "Create" : "Save" }}</button>
					</div>
				</form>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab 
		*ngIf="id !== 0" 
		label="Inventories"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-facility-inventories [facilityId]="id"></pcg-facility-inventories>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab 
		*ngIf='
			id !== 0 
			&& (model$ | async)
		' 
		label="Business Areas"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-facilty-business-areas 
					[businessArea]="businessArea" 
					[id]="id"
					[canEdit]="canEdit"
				></pcg-facilty-business-areas>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab 
		*ngIf='id !== 0' 
		label="Cardinal Accounts"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-shared-facility-cardinal-accounts 
					[id]='id'
					[businessArea]="businessArea"
				></pcg-shared-facility-cardinal-accounts>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab 
		*ngIf='id !== 0' 
		label="Associated Users"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-shared-facility-user-list 
					[id]='id' [businessArea]="businessArea"
					[facilityName]="facilityName"
				></pcg-shared-facility-user-list>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab 
		*ngIf='
			id !== 0 
			&& canViewReports
		' 
		label="Audit History"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-shared-facility-audit-history [id]='id'></pcg-shared-facility-audit-history>
			</div>
		</ng-template>
	</mat-tab>
</mat-tab-group>
