import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getHEROSNav: () => NavRoute = () => {
	const user = getUser();
    const icons = new FaBusinessAreaIconsService();
	const isAdmin = user?.moduleAccess?.filter(o => o.module === BusinessAreaEnum.HEROS
		&& o.permissionRole === PermissionRole.SystemAdmin
	)?.length > 0;

	return {
		name: 'HEROS'
		, href: 'heros/dashboard'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: getHerosNavItems(isAdmin, user)
		, activeNavLink: 'heros/dashboard'
		, secondaryNav: ['HEROS', 'Dashboard']
		// , icon: faMask
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.HEROS)
		, area: 'heros'
		, module: BusinessAreaEnum.HEROS
	};
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}

function getHerosNavItems(isAdmin: boolean, user: UserJsVm) {
	const navItems: NavRoute[] = [];

	navItems.push({
		name: 'Dashboard'
		, exact: true
		, path: 'heros/dashboard'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, activeNavLink: 'heros/dashboard'
		, secondaryNav: ['HEROS', 'Dashboard']
	});		

	navItems.push({
		name: 'Agencies'
		, exact: true
		, path: 'heros/agencies/list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, activeNavLink: 'heros/agencies'
		, secondaryNav: ['HEROS', 'Agencies']
	});

	navItems.push({
		name: 'Applications'
		, exact: true
		, path: 'heros/applications/list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, activeNavLink: 'heros/applications'
		, secondaryNav: ['HEROS', 'Applications']
	});

	navItems.push({
		name: 'Contacts'
		, exact: true
		, path: 'heros/contacts/list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, requireAll: true
		, activeNavLink: 'heros/contacts'
		, secondaryNav: ['HEROS', 'Contacts']
	});

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.HEROS)) {
		navItems.push({
			name: 'Error Reporting'
			, exact: true
			, path: `error-report/error-reports/heros/list/${BusinessAreaEnum.HEROS}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.ErrorReport ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, requireAll: true
			, activeNavLink: 'error-report/error-reports/heros'
			, secondaryNav: ['HEROS', 'Error Reporting']
		});
	}

	if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.HEROS)) {
		navItems.push({
			name: 'Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Reports ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, exact: true
			, path: `reporting/list/${BusinessAreaEnum.HEROS}`
			, activeNavLink: 'reporting/list'
			, secondaryNav: ['HEROS', 'Reporting']
		});
	}

	navItems.push({
		name: 'Admin'
		, moduleAccess: [
			{ 
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User) 
			}
		]
		, href: 'admin/fiscal-years'
		, activeNavLink: 'heros/admin/fiscal-years'
		, secondaryNav: ['Admin', 'Fiscal Years']
		, children: [
			{
				name: 'Fiscal Years'
				, exact: true
				, path: 'admin/fiscal-years'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HEROS ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
				, requireAll: true
				, activeNavLink: 'admin/fiscal-years'
				, secondaryNav: ['Admin', 'Fiscal Years']
			}
			, {
				name: 'FY Budget'
				, exact: true
				, path: 'heros/admin/fy-budget'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HEROS ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, requireAll: true
				, activeNavLink: 'heros/admin/fy-budget'
				, secondaryNav: ['Admin', 'FY Budget']
			}
			, {
				name: 'Rounds'
				, exact: true
				, path: 'heros/admin/rounds/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HEROS ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, requireAll: true
				, activeNavLink: 'heros/admin/rounds'
				, secondaryNav: ['HEROS', 'Admin', 'Rounds']
			}
			, {
				name: 'Attachment Types'
				, exact: true
				, path: 'heros/admin/attachment-types'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HEROS ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, requireAll: true
				, activeNavLink: 'heros/admin/attachment-types'
				, secondaryNav: ['Admin', 'Attachment Types']
			}
			, {
				name: 'Misc Accounts'
				, exact: true
				, path: 'heros/admin/misc-accounts'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HEROS ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, requireAll: true
				, activeNavLink: 'heros/admin/misc-accounts'
				, secondaryNav: ['Admin', 'Misc Accounts']
			}
			, {
				name: 'Ordering Options'
				, exact: true
				, path: 'heros/admin/ordering-options'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HEROS ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, requireAll: true
				, activeNavLink: 'heros/admin/ordering-options'
				, secondaryNav: ['Admin', 'Ordering Options']
			}
		]
	});

	return navItems;
}
