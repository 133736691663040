import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';

/**
 * Notes on animations:
 * 		A trigger is the parent container of any angular animation. Triggers are assigned to elements in the template like this: [@triggerName]
 *
 * 		Different states are defined with the state functions. First argument is the name of the state, and the second argument is a style function
 * 		Style function just contains an object with properties similar to css property names, except in camelCase. e.g. line-height css property becomes lineHeight object property
 *
 * 		Transitions take two arguments, a descriptor of which state is being transferred to/from with an arrow in between, and an animate function.
 * 		The animate function sets the time it takes for the animation to complete and what type of curve is used.
 *
 * 		The state of the trigger can be set with an expression in the template, e.g. <div [@triggerName]="boolean ? 'trueStateName' : 'falseStateName'"></div>
 */

export const detailExpand = trigger('detailExpand', [
	state(
		'collapsed',
		style({ height: '0px', opacity: 0, lineHeight: '0em', padding: '0rem .75rem', border: '0px solid #dee2e6' })
	),
	state('expanded', style({ height: '*', lineHeight: '2em', padding: '0.6rem .75rem', border: '1px solid #dee2e6' })),
	transition('expanded <=> collapsed', animate('180ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);

export const tableEntry = trigger('tableEntry', [
	transition(':enter', [
		style({ height: '0px', opacity: 0, lineHeight: '0em', padding: '0rem .75rem', border: '0px solid #dee2e6' }),
		animate(
			'180ms cubic-bezier(0.4, 0.0, 0.2, 1)',
			style({ height: '*', lineHeight: '2em', padding: '0.6rem .75rem', border: '1px solid #dee2e6' })
		),
	]),
	transition(':leave', [
		style({ height: '*', lineHeight: '2em', padding: '0.6rem .75rem', border: '1px solid #dee2e6' }),
		animate(
			'180ms cubic-bezier(0.4, 0.0, 0.2, 1)',
			style({ height: '0px', opacity: 0, lineHeight: '0em', padding: '0rem .75rem', border: '0px solid #dee2e6' })
		),
	]),
]);

export const notificationSlide = trigger('notificationSlide', [
    transition(':enter', [
        style({
            transform: 'translateX({{direction}})',
            opacity: 0,
            top: '*',
            bottom: '*'
        }),
        animate('300ms ease-in', style({ transform: 'translateX(0)', opacity: 1 }))
    ]),
    transition(':leave', [
        style({
            transform: 'translateX(0)',
            opacity: 0.5
        }),
        animate('300ms ease-in', style({ transform: 'translateX({{direction}})', opacity: 0 }))
    ]),
    transition('* => *', [
        animate('300ms ease-in', style({ top: '*', bottom: '*' }))
    ])
])
