import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getMetabolicFormulaNav: () => NavRoute = () => {

    const user = getUser();
    const icons = new FaBusinessAreaIconsService();
	var navItems: NavRoute[] = [
        {
            name: 'Orders'
            , exact: true
            , path: 'metabolic-formula/orders/list'
            , moduleAccess: [
                {
                    modules: [ BusinessAreaEnum.MetabolicFormula ]
                    , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
                }
            ]
            , activeNavLink: 'metabolic-formula/orders'
			, secondaryNav: ['Metabolic Formula', 'Orders']
        }
        , {
            name: 'Facilities'
            , exact: true
            , path: 'metabolic-formula/facilities/list'
            , moduleAccess: [
                {
                    modules: [ BusinessAreaEnum.MetabolicFormula ]
                    , permissionRoles: [
                        PermissionRole.UserAdmin
                        , ...SecurityService.setMinRole(PermissionRole.Manager)
                    ]
                }
            ]
            , activeNavLink: 'metabolic-formula/facilities'
			, secondaryNav: ['Metabolic Formula', 'Facilities']
        }
    ];

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.MetabolicFormula)) {
		navItems.push({
			name: 'Error Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.ErrorReport ]
                    , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, path: `error-report/error-reports/metabolic-formula/list/${BusinessAreaEnum.MetabolicFormula}`
            , activeNavLink: 'error-report/error-reports/metabolic-formula'
            , secondaryNav: ['Metabolic Formula', 'Error Reporting']
		});
	}

    if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.MetabolicFormula)) {
        navItems.push({
            name: 'Reporting'
            , moduleAccess: [
                {
                    modules: [ BusinessAreaEnum.Reports ]
                    , permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
                }
            ]
            , exact: true
            , path: `reporting/list/${BusinessAreaEnum.MetabolicFormula}`
            , activeNavLink: 'reporting/list'
            , secondaryNav: ['Metabolic Formula', 'Reporting']
        });
    }

    navItems.push({
        name: 'Admin'
        , exact: true
        , path: 'metabolic-formula/admin'
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.MetabolicFormula ]
                , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
            }
        ]
        , activeNavLink: 'metabolic-formula/admin'
        , secondaryNav: ['Metabolic Formula', 'Admin']
    });

    return {
        name: 'Metabolic Formula'
        , canActivate: [ AuthGuard ]
        , href: 'metabolic-formula/orders/list'
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.MetabolicFormula ]
                , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
            }
        ]
        , children: navItems
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.MetabolicFormula)
		, area: 'metabolic-formula'
		, module: BusinessAreaEnum.MetabolicFormula
    }
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}