import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faFilePrescription, faRandom, faStepForward, faUser, faRefresh} from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';

import { PatientDataVm } from 'app/shared/generated/Models/PatientDataVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

@UntilDestroy()
@Component({
    selector: 'pcg-patient-phi-card',
    templateUrl: './patient-phi-card.component.html',
    styleUrls: ['./patient-phi-card.component.scss'],
    standalone: false
})
export class PatientPhiCardComponent implements OnInit {

	constructor(public http: HttpClient) { }

	@Input() patientData: any;
	@Input() isFacility: boolean;
	@Input() addressValidationError: string;
	@Input() facilityCodeValidationError: string;
	@Input() shipmentId: number;
	@Output() refreshQs1 = new EventEmitter();
	
	googleAddress : string = '';
	faUser = faUser;
	faTransfer = faRandom;
	faEOS = faStepForward;
	faEarlyRx = faFilePrescription;
	faRefresh = faRefresh;

	ngOnInit() { 
		let encodedAddress = encodeURIComponent(
			`${this.patientData?.address}, ${this.patientData?.address2}, ${this.patientData?.city}, ${this.patientData?.state}, ${this.patientData?.zip},`
		);
		this.googleAddress = `https://google.com/maps/place/${encodedAddress}`;		
	}

	getLastFillDateColor() {
		if (this.patientData?.lastFillDateColor === 'yellow') { this.patientData.lastFillDateColor = 'red'; }
		return this.patientData?.lastFillDateColor;
	}

	updateQs1Address() {
		this.http.get(`api/Inventory/QS1/UpdateRxAddressData?rxNumber=${this.patientData.rxNumber}&shipmentId=${this.patientData.shipmentId == null ? this.shipmentId : this.patientData.shipmentId}`)
			.subscribe((vm: PatientDataVm) => {
				if (vm != this.patientData) {
					this.patientData.address = vm.address;
					this.patientData.address2 = vm.address2;
					this.patientData.city = vm.city;
					this.patientData.state = vm.state;
					this.patientData.zip = vm.zip;
					if(!FormatHelperService.GetIsNullyOrWhitespace(vm.address)) {
						this.addressValidationError = null;
						this.refreshQs1.emit();
					}
				}
			}
		);
	}
}
