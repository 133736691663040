import { Injectable } from '@angular/core';
import { 
    faGear, 
    faDollarSign,
    faBoxesPacking, 
    faBomb, 
    faCircleInfo, 
    faCaduceus, 
    faBlockQuestion, 
    faShelves, 
    faBottleBaby, 
    faTablets, 
    faPrescriptionBottlePill, 
    faFileLines, 
    faPrescription,
    faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';

/*
    Centralized area for Business Area to icon
    Used for navigation and notification icons
*/
@Injectable({
  providedIn: 'root'
})
export class FaBusinessAreaIconsService {

    getIconByBusinessArea(ba: BusinessAreaEnum) {
        switch(BusinessAreaEnum.toDescription(ba)){
            case "HEROS":
                return faCaduceus;
            case "Pharmacy":
                return faPrescriptionBottlePill;
            case "CI Repack":
                return faBoxesPacking;
            case "NDCs":
                return faTablets;
            case "Metabolic Formula":
                return faBottleBaby;
            case "ICQ":
                return faBlockQuestion;
            case "Support":
                return faCircleInfo;
            case "Reports":
                return faFileLines;
            case "Error Reporting":
                return faBomb;
            case "System Inventory":
                return faShelves;
            case "Budget & Allocations":
                return faDollarSign;
            case "Rx Connects":
                return faPrescription;
            case "Admin":
                return faGear;
            default:
                return faQuestion;
        }
    }    

    getIconStyleByBusinessArea(ba: BusinessAreaEnum) {
        switch(BusinessAreaEnum.toDescription(ba)){
            case "HEROS":
                return {color: "#FFFFFF", background: "#FF0000"};
            case "Pharmacy":
                return {color: "#FFFFFF", background: "#FA6400"};
            case "CI Repack":
                return {color: "#FFFFFF", background: "#FFFF00"};
            case "NDCs":
                return {color: "#FFFFFF", background: "#00FF00"};
            case "Metabolic Formula":
                return {color: "#FFFFFF", background: "#0000FF"};
            case "ICQ":
                return {color: "#FFFFFF", background: "#000066"};
            case "Support":
                return {color: "#FFFFFF", background: "#AA00DD"};
            case "Reports":
                return {color: "#FFFFFF", background: "#FF0000"};
            case "Error Reporting":
                return {color: "#FFFFFF", background: "#FA6400"};
            case "System Inventory":
                return {color: "#FFFFFF", background: "#FFFF00"};
            case "Budget & Allocations":
                return {color: "#FFFFFF", background: "#00FF00"};
            case "Rx Connects":
                return {color: "#FFFFFF", background: "#0000FF"};
            case "Admin":
                return {color: "#FFFFFF", background: "#000066"};
            default:
                return {color: "#FFFFFF", background: "#AA00DD"};
        }
    }  
}
