import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faFilePrescription, faRandom, faStepForward } from '@fortawesome/pro-solid-svg-icons';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { ShipmentListRxModalVm } from 'app/shared/generated/Inventory/Models/Shipping/ShipmentListRxModalVm';

@Component({
    selector: 'pcg-shipment-list-rx-modal',
    templateUrl: './shipment-list-rx-modal.component.html',
    styleUrls: ['./shipment-list-rx-modal.component.scss'],
    standalone: false
})
export class ShipmentListRxModalComponent implements OnInit {

	@Input() shipmentId: number;
	@Input() modal: any;
	@Input() isUser: boolean = false;

	@Output() reloadTable = new EventEmitter();

	rxList: ShipmentListRxModalVm[] = [];
	isEmpty = false;
	
	faTransfer = faRandom;
	faEOS = faStepForward;
	faEarlyRx = faFilePrescription;

	constructor(
        private http: HttpClient
        , private ms: SystemMessageService
    ) { }

	ngOnInit() {
		this.http.get(
			`api/Inventory/${this.isUser ? 'RxFulfillmentUser' : 'Shipping'}/GetShipmentListRxs?shipmentId=${this.shipmentId}`
		).subscribe((rxListResult: ShipmentListRxModalVm[]): void => {
			if (rxListResult === null) {
				this.ms.setSystemMessage("The shipment you selected does not exist. Table has been updated.", "warning");
				this.reloadTable.emit();
			} else {
				this.rxList = rxListResult;
				this.isEmpty = this.rxList.length === 0;
			}
		});
	}
}
