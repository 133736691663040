import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faComments } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle, faHourglassEnd, faPrescriptionBottleAlt, faUserMd } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { subWeeks, subYears } from 'date-fns';

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { RxDataStorageService } from 'app/fulfillment/services/rx-data-storage.service';
import { InventoryService } from 'app/inventory/services/inventory.service';
import { FaIconName } from 'app/shared/fa-num-icon/fa-icon-name/fa-icon-name';
import { FaIconPosition, FaPositionUnits } from 'app/shared/fa-num-icon/fa-icon-position/fa-icon-position';
import { FaNumColor } from 'app/shared/fa-num-icon/fa-num-color/fa-num-color.enum';
import { ShipmentListVm } from 'app/shared/generated/Inventory/Models/Shipping/ShipmentListVm';
import { DateRange } from 'app/shared/models/date-range';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { TableComponent } from 'app/shared/table/table.component';
import { PatientDataVm } from 'app/shared/generated/Models/PatientDataVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { SelectItem } from 'app/shared/models/select-item';
import { TableTopLegendVm } from 'app/shared/table/table-top/table-top-legend/table-top-legendVm';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';

@UntilDestroy()
@Component({
    selector: 'pcg-shipment-list',
    templateUrl: './shipment-list.component.html',
    styleUrls: ['./shipment-list.component.scss'],
    standalone: false
})
export class ShipmentListComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<ShipmentListVm>;
	@ViewChild('pfsForms', { static: true }) selectPfsForm: PcgSelectComponent;
	@ViewChild('facilitySelect', { static: true }) facilitySelect: PcgSelectComponent;
	@Input() isCentralized = false;

	fedexLink = 'https://www.fedex.com/apps/fedextrack/?action=track&cntry_code=us&locale=en_US&trackingnumber=';
	upsLink = 'https://www.ups.com/track?loc=en_US&tracknum=';
	inputPlaceholder = 'Filter Rx';
	faIconName = new FaIconName();
	faNumColor = FaNumColor;

	columns = ShipmentListVm.ColumnDefs;
	facilitySelectItems: SelectItem[] = [];	
	model: PatientDataVm;
    user: UserJsVm;

	// Table filters
	filters = {
		inventorySiteId: null
		, firstName: null
		, lastName: null
		, dob: "" // Made this an empty string to compare since element changes it to this anyways.
		, zip: null
		, date: new DateRange(subWeeks(new Date(), 2), new Date())
		, status: '[]'
		, strShipmentIds: null
		, facilityName: null
		, pfsFormId: null
		, isNotActive: false
		, confirm: null
		, facilityId: null
		, userId: null
	};

	// Table filters
	emptyFilters = {
		inventorySiteId: null
		, firstName: null
		, lastName: null
		, dob: ""// Made this an empty string to compare since element changes it to this anyways.
		, zip: null
		, date: new DateRange(subWeeks(new Date(), 2), new Date())
		, status: '[]'
		, strShipmentIds: null
		, facilityName: null
		, pfsFormId: null
		, isNotActive: false
		, facilityId: null
		, userId: null
	};

	selectedStatus = new Array();
	initFilters = Object.assign({}, this.filters);

	filterMap = {
		"inventorySiteId": "Inventory Site"
		, "firstName": "First Name"
		, "lastName": "Last Name"
		, "dob": "DOB"
		, "zip": "Zip"
		, "date": "Date Created"
		, "status": "Status"
		, "facilityName": "Facility Name"
		, "pfsFormId": "PFS Form"
		, "isNotActive": "Inactive Fulfillments"
		, "facilityId": "Facility"
	};

	// For DateRange filter 
	maxDate = new Date();
	minDate = new Date(1900, 0, 1);
	
	faUserMd = faUserMd;
	faExclamationCircle = faExclamationCircle;
	faHourglassEnd = faHourglassEnd;
	rxCountIcon = faPrescriptionBottleAlt;
	faComments = faComments;
	commentPosition: FaIconPosition = new FaIconPosition(-10, 20, null, null, FaPositionUnits.pixels);

	dangerColor = '#dc3545';
	warningColor = '#ffc107';
	sortDir = 'desc';
	inventorySiteName: string;
	dataSource = "api/Inventory/Shipping/GetShipmentList";
	createSource = "api/Inventory/Shipping/CreateShipment/";
	editSource = "/pharmacy/inventories/fulfillment/edit/";
	errorStr = "";
    sortData = [[ "dateCreatedStr", this.sortDir ]];

	inventorySiteId: number; // Shared throughout page. Comes from URL.	
	rxListShipmentId = 0;// Used for the rx list modal and comments modal components 
	newFacilityId: number;
		
	canEdit: boolean; 
	canCreateFulfillments: boolean; 
	inventoryEditAccess: boolean;
	currentSiteName: string;
	firstLoad = true;
	userId: number;
	isUser: boolean = false;
	isNewFacility = false;
	isError = false;
	hasMultipleInvChoices = false;

	// Colors for table row highlighting and table legend
	lastViewedRxColor: string = '#d5def3';

    // Table legend
	legend: TableTopLegendVm[] = 
	[
		{
			id: 0,
			colorCode: this.lastViewedRxColor,
			description: 'Highlights the fulfillment containing the last viewed Rx'
		}
	];

	constructor(
		private route: ActivatedRoute
		, private title: Title
		, private sec: SecurityService
		, private httpClient: HttpClient
		, public router: Router
		, public modalService: NgbModal
		, private rxDataStorageService: RxDataStorageService
		, public inventoryService: InventoryService
		, public btnFilterService: ButtonFilterService
		, private formatHelper: FormatHelperService
	) {
		title.setTitle('Rx Fulfillment List');
		// Don't want to keep Rx Data for inventory specific interactions
		if (!this.isCentralized) { this.rxDataStorageService.resetInventoryChoiceModalData(); }
	}

	ngOnInit() {
		this.user = this.sec.getUser();
        this.userId = this.user?.id;
		this.canEdit = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.canCreateFulfillments = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, [ 
				PermissionRole.User
				, PermissionRole.Technician
				, PermissionRole.Pharmacist 
			]
			, false
		);
		this.inventoryEditAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);	
		// Set the inventorySiteId
		this.route.paramMap.subscribe(params => {
			if (!this.isCentralized) { this.initFilters.inventorySiteId = parseInt(params.get('id'), 0); }
			if (this.firstLoad) {
				this.filters.inventorySiteId = this.initFilters.inventorySiteId;
				this.initFilters = Object.assign({}, this.filters);
				this.serializeStatus();
			} else { this.resetFilters(); } // Reset filters when route params(id: changing inventories) change after component is first loaded.
			this.inventorySiteId = this.filters.inventorySiteId;
			if (this.inventorySiteId != null) { this.getSiteName(); }
		});

		this.isUser = this.user?.isRxUser;
		if (this.isUser) { 
			this.filters.userId = this.userId; 
			this.dataSource = "api/Inventory/RxFulfillmentUser/GetShipmentList";
			this.createSource = "api/Inventory/RxFulfillmentUser/CreateShipment/";
			this.editSource = "/pharmacy/fulfillment/edit/";
            this.title.setTitle('Refill Request List');
            this.sortData = [[ "fulfillmentStatus", "asc" ]];
		}

		// Set the status from the flowmometer
		this.route.queryParamMap.subscribe(qParams => {
			const statusParam = qParams.get("status");
			// Reset filters when route query params(tab: changing inventory tab, or status: flowmometer) change after component is first loaded.
			if (!this.firstLoad) { this.resetFilters(); }
			if (statusParam) {
				sessionStorage.removeItem('rxFulfillmentFilters');
                this.selectedStatus = [parseInt(qParams.get('status'))];
				this.serializeStatus();
				this.filters.date = new DateRange(subYears(new Date(), 1), new Date());
				this.sortDir = 'asc';
			} else { this.preFilter(); }
		});
		Object.assign({}, this.filters);

		this.firstLoad = false;// Set this to false so the filters will be reset when the route params change.
		this.columns.get('inventory').isVisible = this.isCentralized;

		// Set the siteName
		if (this.inventorySiteId != null) {
			this.inventoryService.getSiteName(this, this.inventorySiteId).subscribe(siteName => { this.inventorySiteName = siteName; });
		}
		this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });
		this.setScanFocus()

		// Hide delete button by default for users who cannot delete
		if (!this.inventoryEditAccess) this.columns.get('canDelete').isVisible = false;
	}

	scanSuccessFromList($event: number[]) {
		this.filters.strShipmentIds = JSON.stringify($event);
		this.table.ajaxReload();
	}

	resetFilters() {
		// Reset filters by copy filters values set on init 
		this.filters = Object.assign(this.filters, this.initFilters);
		this.selectedStatus = JSON.parse(this.filters.status);
		this.inputPlaceholder = 'Filter Rx';
		// Update url when it contains a status param.
		if (this.route.snapshot.queryParamMap.get('status')) { this.router.navigateByUrl('pharmacy/fulfillment/list'); }
		sessionStorage.removeItem('rxFulfillmentFilters');
		this.table.ajaxReload();
	}

	newFulfillment(isFacility: boolean) {
		this.httpClient.get(`${this.createSource}?inventorySiteId=${this.inventorySiteId}&isFacility=${isFacility}`)
			.subscribe((shipmentId: number) => {
				this.router.navigateByUrl('/pharmacy/inventories/fulfillment/edit/'
					+ encodeURIComponent(`${shipmentId}`) + '/' + encodeURIComponent(`${this.inventorySiteId}`));
			}
		);
	}	

	navigateToInventoryEdit() {
		let inventoryId = this.inventorySiteId;
		if (
			this.inventoryEditAccess 
			&& inventoryId
		) { this.router.navigate([`/pharmacy/inventories/edit/${inventoryId}`]); }
	}

	getSiteName() {
		this.httpClient.get('api/Inventory/Inventory/GetInventorySiteName/' + this.inventorySiteId)
			.subscribe((name: string) => { this.currentSiteName = name; });
	}

	// Centralized Rx-Fulfillment Inventory Choice Modal
	openInventoryChoiceModal(
		modal: any
		, isFacility: boolean
	): void {
		this.rxDataStorageService.IsFacility = isFacility;
		this.modalService.open(modal, { animation: false });
	}	

	openRxListModal(
		modal: any
		, shipmentId: number
	): void {
		this.rxListShipmentId = shipmentId;
		this.modalService.open(modal, { animation: false });
	}

	openCommentsModal(
		modal: any
		, shipmentId: number
	) {
		this.rxListShipmentId = shipmentId;
		this.modalService.open(
			modal
			, {
				animation: false
				, beforeDismiss: () => {
					this.table?.ajaxReload();
					return true;
				}
			}
		);
	}

	preFilter() {
		if (this.hasStoredFilters()){
			const preFilters = JSON.parse(this.getStoredFilters());
			this.filters.firstName = preFilters?.firstName;
			this.filters.lastName = preFilters?.lastName;
			this.filters.dob = preFilters?.dob;
			this.filters.zip = preFilters?.zip;
			this.filters.facilityName = preFilters?.facilityName;
			this.filters.pfsFormId = preFilters?.pfsFormId;
			if (!this.inventorySiteId) { this.filters.inventorySiteId = preFilters?.inventorySiteId; }
			this.filters.zip = preFilters?.zip;
			this.filters.isNotActive = preFilters?.isNotActive;
			this.filters.status = preFilters?.status;
			this.filters.facilityId = preFilters?.facilityId;
			this.selectedStatus = JSON.parse(preFilters?.status);
			if (
				preFilters.date !== undefined
				&& !this.hasNullDate(preFilters.date.dateBegin) 
				&& !this.hasNullDate(preFilters.date.dateEnd)
			) { this.filters.date = new DateRange(new Date(preFilters.date.dateBegin), new Date(preFilters.date.dateEnd)); }
		}
	}

	filterChange = () => this.setStoredFilters();
	// Checking to see if the filters have been changed since init.
	// Used to determine when to show the reset filters button next to the scan.
	filtersTouched() { return JSON.stringify(this.filters) !== JSON.stringify(this.initFilters); }
	setScanFocus() { setTimeout(() => { document.getElementById('scanRx')?.focus(); }, 1); }
	serializeStatus() { this.filters.status = JSON.stringify(this.selectedStatus); }
	scanRxNumber(rx: string) { this.inputPlaceholder = 'Filtered by Rx: ' + rx; }
	navigateToFulfillment(inventoryId: string) { this.router.navigate([`/pharmacy/inventories/fulfillment/list/${inventoryId}`]); }
	getIsFulfillmentOpenedLink = (isFulfillmentOpen: boolean): string => isFulfillmentOpen ? 'color:purple;' : '';
	// HELPERS
	hasNullDate = (date: Date) : boolean => date == null; 
	// Stored Filters
	hasStoredFilters = () : boolean => sessionStorage.getItem('rxFulfillmentFilters') !== null;
	setStoredFilters = () : void => sessionStorage.setItem('rxFulfillmentFilters', JSON.stringify(this.filters));
	getStoredFilters = () => sessionStorage.getItem('rxFulfillmentFilters');

	// #region User functions
	createNewFacilityRx() {
		this.httpClient.get(`api/Select/Facilities?userId=${this.userId}&businessArea=4&formTypeId=1`)
			.subscribe((items: SelectItem[]) => {
				this.facilitySelectItems = items;
				if (items.length === 1) { this.newFacilityId = items[0].value; }
			}
		);
		this.rxDataStorageService.IsFacility = true;
		this.isNewFacility = true;
		this.isError = false;
		this.errorStr = "";
	}

	cancelRx() {
		this.rxDataStorageService.IsFacility = false;
		this.isNewFacility = false;
		this.newFacilityId = null;
		this.isError = false;
		this.errorStr = "";
	}

	scanSuccessInventoryList($event: PatientDataVm) {
		if (!this.formatHelper.GetIsNully($event.errorMessage)) { 
			this.isError = true;
			this.errorStr = $event.errorMessage;
		} else if ($event.inventories?.length === 0) {
			this.isError = true;
			this.errorStr = `Product NDC (${this.formatHelper.FormatNdc($event.ndc)}) not found for any inventory.`;
		} else if ($event.inventories?.length === 1) {
			// Head straight to a new RxFulfillment
			this.chooseInventory($event.inventories[0].inventorySiteId, $event.inventories[0].inventoryName);
		} else if ($event.inventories?.length > 1) {
			// Set the modal up for the user to select an inventory choice
			//this.title = "Choose an Inventory for " + this.subTitle;
			this.hasMultipleInvChoices = true;
			this.model = $event;
		} else { 
			this.isError = true;
			this.errorStr = `There were no inventories found for Rx ${$event.rxNumber}`;
		}
	}

	chooseInventory(
		invId: number
		, invName: string
	) {
		let isConfirmed = true;
		if (this.hasMultipleInvChoices) { isConfirmed = confirm(`Inventory: ${invName} was selected. Is this the correct inventory?`); }
		
		if (!isConfirmed) { return null; }
		else {
			const patientData: PatientDataVm = this.rxDataStorageService.PatientData;
			patientData.isFacility = this.rxDataStorageService.IsFacility;
			
			this.httpClient.post(
				`api/Inventory/QS1/ValidateAndSaveRxData/` 
					+ `?inventorySiteId=${invId}` 
					+ `&isUser=${this.isUser}`
					+ `&facilityId=${this.newFacilityId}`
				, patientData
			).subscribe((returnData: PatientDataVm) => {
				if (
					returnData.errorMessage === null 
					|| returnData.errorMessage === undefined
				) {
					// Need to write over the old patient data since there is a facility address check that may provide new info not available ealier
					if (
						returnData.address !== patientData.address 
						|| returnData.address2 !== patientData.address2 
						|| returnData.city !== patientData.city
						|| returnData.state !== patientData.state 
						|| returnData.zip !== patientData.zip
					) { this.rxDataStorageService.PatientData = returnData; }

					// Now that validation has passed, and a shipment has been created, we can navigate to it
					this.router.navigateByUrl('/pharmacy/fulfillment/edit/'
						+ encodeURIComponent(`${returnData.shipmentId}`) + '/' + encodeURIComponent(`${invId}`));
				} else { 
					this.isError = true;
					this.errorStr = returnData.errorMessage; 
				}
			});
		}
	}
	// #endregion
}
