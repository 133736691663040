<div 
	class="card-body"
	data-dd-privacy="mask"
>
	<h5 class="card-title">
		<fa-icon 
			[icon]="faUser" 
			class="mr-2"
		></fa-icon>
		{{ patientData?.firstName + " " + (patientData?.middleInitial ? patientData?.middleInitial + " " : "") + patientData?.lastName }}
	</h5>
	<h6 class="card-subtitle mb-2 text-muted">DOB: {{ patientData?.dateOfBirth }}</h6>

	<div class="card-text">
		<span [class.d-none]='!patientData?.facility'>
			{{ patientData?.facility + (patientData?.facilityCode ? (" : " + patientData?.facilityCode) : "") }}
			@if (facilityCodeValidationError != null) { <p class='text-danger small'>{{ facilityCodeValidationError }}</p> }
			@else { <br /> }
		</span>

		<div [class.d-none]='!patientData?.address'>
			<a 
				[href]='googleAddress' 
				target='_blank'
			>{{ patientData?.address + " " + patientData?.address2 }}</a>
			<br />
			{{ patientData?.city + (patientData?.state ? ", " + patientData?.state : "") + " " + patientData?.zip }}
			<br />
		</div>

		@if (addressValidationError != null) { 
			<p class='text-danger small'>{{ addressValidationError }}</p> 
			<p 
				[class.d-none]='!patientData?.address' 
				class='text-danger small'
			>
				<a 
					target="_blank" 
					[href]="googleAddress"
				>Suggested Address</a>
			</p>
		}		
		<br />

		<span [class.d-none]='!patientData?.lastFillDate'>
			<span 
				class="h6 card-subtitle" 
				ngbTooltip='If the prescription has been filled recently the date is red. If the prescription needs to be filled it is orange.'
			>Last Fill Date: </span>
			<label [style.color]="getLastFillDateColor()">{{ patientData?.lastFillDate }}</label>
		</span>

		<span [class.d-none]='!patientData?.nextFillDate || isFacility'>
			<br />
			<span class="h6 card-subtitle">Next Fill Date: </span>{{ patientData?.nextFillDate }}
		</span>

		<span 
			[class.d-none]='!patientData?.qs1Status' 
			data-dd-privacy="allow" 
			class="h6 card-subtitle col-md-5 m-0 p-0"
		>
			<br />
			<span 
				class="h6 card-subtitle" 
				ngbTooltip='This is the status of the Rx in QS1.'
			>QS1 Status: </span>{{ patientData?.qs1Status }}
		</span>

		<span class="col-md-3"></span>

		<span 
			(click)="updateQs1Address()" 
			id='refresh' 
			data-dd-privacy="allow" 
			class="col-md-4 float-right m-0 p-0 "
		>
			<fa-icon 
				[icon]="faRefresh" 
				class="mr-2"				
			></fa-icon> Refresh from QS1
		</span>

		<span [class.d-none]='!patientData?.pfsFormID'>
			<br />
			<span 
				class="h6 card-subtitle" 
				ngbTooltip='This prescription is part of a Refill Request Form that was generated in PFS. Click the link to view the form details.'
			>PFS Form: </span>
			<a 
				href='{{patientData?.pfsUrl}}' 
				target="_blank"
			>{{ patientData?.pfsRequestType }}</a>
		</span>

		<span [class.d-none]='!patientData?.pfsFormID'>
			<br />
			<ng-template #pfsFlagsTooltip>
				<table style="border: 0px;">
					<tr>
						<td style="text-align: left;">
							<fa-icon 
								[icon]="faEOS" 
								class="mr-2"
							></fa-icon>
						</td>
						<td style="text-align: center;"> = </td>
						<td style="text-align: left;">EOS</td>
					</tr>
					<tr>
						<td style="text-align: left;">
							<fa-icon 
								[icon]="faEarlyRx" 
								class="mr-2"
							></fa-icon>
						</td>
						<td style="text-align: center;"> = </td>
						<td style="text-align: left;">Early Refill</td>
					</tr>
					<tr>
						<td style="text-align: left;">
							<fa-icon 
								[icon]="faTransfer" 
								class="mr-2"
							></fa-icon>
						</td>
						<td style="text-align: center;"> = </td>
						<td style="text-align: left;">Patient Facility Transfer</td>
					</tr>
				</table>
			</ng-template>

			<span 
				class="h6 card-subtitle" 
				[ngbTooltip]='pfsFlagsTooltip'
			>PFS Flags: </span>
			&nbsp;
			<fa-icon 
				[class.d-none]='!patientData?.isEos' 
				[icon]="faEOS" 
				class="mr-2" 
				ngbTooltip="EOS"
			></fa-icon>
			&nbsp;
			<fa-icon 
				[class.d-none]='!patientData?.isOverrideLastFillDate' 
				[icon]="faEarlyRx" 
				class="mr-2" 
				ngbTooltip="Early Refill"
			></fa-icon>
			&nbsp;
			<fa-icon 
				[class.d-none]='!patientData?.isOverridePatientTransfer' 
				[icon]="faTransfer" 
				class="mr-2" 
				ngbTooltip="Patient Facility Transfer"
			></fa-icon>
		</span>
	</div>
</div>