import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getBANav: () => NavRoute = () => {
    const icons = new FaBusinessAreaIconsService();
	var navItems: NavRoute[] = [
		{
			name: 'Budget Tracking'
			, exact: true
			, path: 'ba/budgets/list'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.BA ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, featureFlag: true
			, activeNavLink: 'ba/budgets'
			, secondaryNav: ['ba', 'Budget Tracking']
		}
		, {
			name: 'Cardinal Invoices'
			, exact: true
			, path: 'ba/cardinal-invoices'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.BA ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, activeNavLink: 'ba/cardinal-invoices'
			, secondaryNav: ['ba', 'Cardinal Invoices']
		}
		, {
			name: 'Admin'
			, href: 'ba/admin/fiscal-years'
			, activeNavLink: 'ba/admin/fiscal-years'
			, secondaryNav: ['ba', 'Admin', 'Fiscal Years']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.BA ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, children: [
				{
					name: 'Fiscal Years'
					, exact: true
					, path: 'ba/admin/fiscal-years'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.BA ]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
						}
					]
					, activeNavLink: 'ba/admin/fiscal-years'
					, secondaryNav: ['ba', 'Admin', 'Fiscal Years']
				}
			]
		}
	];

	return {
		name: 'Budget & Allocation'
		, href: 'ba/budgets/list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.BA ], 
				permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: navItems
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.BA)
		, area: 'ba'
		, module: BusinessAreaEnum.BA
	};
};
