import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getIcqNav: () => NavRoute = () => {

    const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.
    const icons = new FaBusinessAreaIconsService();

    if (user !== null) {
		var navItems: NavRoute[] = [
			{
				name: 'Review Queue'
				, exact: true
				, path: 'icq/review-queue/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'icq/review-queue'
				, secondaryNav: ['ICQ', 'Review Queue']
			}
		];

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.ICQ)) {
			navItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ErrorReport ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
				, exact: true
				, path: `error-report/error-reports/icq/list/${BusinessAreaEnum.ICQ}`
				, activeNavLink: 'error-report/error-reports/icq'
				, secondaryNav: ['ICQ', 'Error Reporting']
			});
		}

		if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.ICQ)) {
			navItems.push({
				name: 'Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Reports ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, exact: true
				, path: `reporting/list/${BusinessAreaEnum.ICQ}`
				, activeNavLink: 'reporting/list'
				, secondaryNav: ['ICQ', 'Reporting']
			});
		}

		navItems.push({
			name: 'Admin'
			, href: 'icq/admin/questions'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.ICQ ]
					, permissionRoles: sec.setMinRole(p.SystemAdmin)
				}
			]
			, activeNavLink: 'icq/admin/questions'
			, secondaryNav: ['ICQ', 'Admin', 'Questions']
			, children: [
				{
					name: 'Sections'
					, exact: true
					, path: 'icq/admin/sections/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ICQ ]
							, permissionRoles: sec.setMinRole(p.SystemAdmin)
						}
					]
					, activeNavLink: 'icq/admin/sections'
					, secondaryNav: ['ICQ', 'Admin', 'Sections']
				}
				, {
					name: 'Issues'
					, exact: true
					, path: 'icq/admin/issues/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ICQ ]
							, permissionRoles: sec.setMinRole(p.SystemAdmin)
						}
					]
					, activeNavLink: 'icq/admin/issues'
					, secondaryNav: ['ICQ', 'Admin', 'Issues']
				}
				, {
					name: 'Questions'
					, exact: true
					, path: 'icq/admin/questions/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ICQ ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'icq/admin/questions'
					, secondaryNav: ['ICQ', 'Admin', 'Questions']
				}
				, {
					name: 'Surveys'
					, exact: true
					, path: 'icq/admin/surveys/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ICQ ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'icq/admin/surveys'
					, secondaryNav: ['ICQ', 'Admin', 'Surveys']
				}
				, {
					name: 'Settings'
					, exact: true
					, path: 'icq/admin/settings'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ICQ ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'icq/admin/settings'
					, secondaryNav: ['ICQ', 'Admin', 'Settings']
				}
			]
		});

        return {
            name: 'ICQ'
            , canActivate: [ AuthGuard ]
            , moduleAccess: [
                {
					modules: [ BusinessAreaEnum.ICQ ]
					, permissionRoles: sec.setMinRole(p.User)
				}
            ]
			, href: 'icq/review-queue/list'
            , children: navItems
			, icon: icons.getIconByBusinessArea(BusinessAreaEnum.ICQ)
			, area: 'icq'
			, module: BusinessAreaEnum.ICQ
        };
    }

    return { name: "" };
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
