import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { UiSwitchComponent } from "ngx-ui-switch";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { PcgSelectComponent } from "app/shared/form-elements/components/pcg-select-component/select.component";
import { ActivationEnum } from "app/core/enums/generated/ActivationEnum";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { EditFacilityVm } from "app/shared/generated/Administration/Models/Facilities/EditFacilityVm";
import { ActivationService } from "app/shared/services/activation.service";
import { FormatHelperService } from "app/shared/helpers/format-helper.service";
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { ConfirmationModalComponent } from 'app/shared/form-elements/components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: "pcg-shared-edit-facility",
    templateUrl: "./edit-facility.component.html",
    styleUrls: ["./edit-facility.component.scss"],
    standalone: false
})
export class EditFacilityComponent implements OnInit {

	@Input() businessArea: BusinessAreaEnum;
	@Input() backBtnPlacement = "auto";

	@ViewChild("isActive", { static: false }) isActive: UiSwitchComponent;
	@ViewChild("isSameAddress", { static: false }) isSameAddress: UiSwitchComponent;
	@ViewChild("isShipAddressValidated", { static: false }) isShipAddressValidated: UiSwitchComponent;
	
	formGroup = EditFacilityVm.Form;
	model = EditFacilityVm.GetModel(this.formGroup);
	model$: Observable<EditFacilityVm>;
	api = "api/Select/SmartyCounties";

	canEdit = false;
	canDeactivate = false;
	canViewReports = false;
	canManualEntry = true;
	shipAddressDisabled = false;
    oldIcqParticipant = false;

	id: number;

	facilityName: string;
	activeTab = "Details";
	oldShipSmartyAddress: string;
	oldShipFacilityName: string;
	faIcon = { faExternalLink };

	// Back Button Variables
	backBtnLink: string;
	backBtnTooltip: string;

	constructor(
		private route: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, title: Title
		, private sec: SecurityService
		, private activationService: ActivationService
		, public ms: SystemMessageService
        , public modalService: NgbModal
	) { title.setTitle("Edit Facility"); }

	ngOnInit() {
		this.canEdit = this.sec.hasModuleAccess(
			[
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
				, BusinessAreaEnum.Admin
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewReports = this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.Admin
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.editFacility();
		if (this.route.snapshot.queryParamMap.get("tab")) {
			this.activeTab = this.route.snapshot.queryParamMap.get("tab");
		}
		this.backBtnTooltip = BusinessAreaEnum.toDescription(this.businessArea);
		if (
			this.businessArea === null 
			|| this.businessArea === undefined 
			|| this.businessArea === BusinessAreaEnum.Admin
		) {
			if (
				this.sec.hasModuleAccess(
					[ BusinessAreaEnum.Admin ]
					, [ ...SecurityService.setMinRole(PermissionRole.SystemAdmin) ]
				)
			) {
				this.canEdit = true;
				this.canDeactivate = true;
			}
			this.backBtnTooltip = "Global";
			this.backBtnLink = "/admin/organizations/facilities/list";
		} else if (this.businessArea === BusinessAreaEnum.CI) {
			this.backBtnLink = "/ci-repack/facilities/list";
		} else if (this.businessArea === BusinessAreaEnum.MetabolicFormula) {
			this.backBtnLink = "/metabolic-formula/facilities/list";
		}

		this.formGroup.get('facilityType')?.disable();
	}

	getMedicalDirector = () => this.hasMedicalDirector() 
		? this.model?.medicalDirectorName 
		: "Set on the Organization";

	hasMedicalDirector = () => !FormatHelperService.GetIsNullyOrWhitespace(this.model.medicalDirectorName);

	editFacility() {
		this.route.paramMap.subscribe((map) => {
			this.id = parseInt(map.get("id"), 10);
			this.model$ = this.httpClient.get(`api/Administration/Facility/GetFacility/${this.id}`)
				.pipe(
                    tap((vm: EditFacilityVm) => {
                        if (vm.countyId === 0) { vm.countyId = null; } // preventing a blank selection on load
                        this.formGroup.setValue(vm);
                        this.facilityName = vm.facilityName;
                        this.oldShipFacilityName = this.model.shipFacilityName;
                        this.oldShipSmartyAddress = this.model.shipSmartyAddress;
                        this.oldIcqParticipant = this.model.isIcqParticipant;
                        this.toggleShippingFormActivation(!this.model.isSameAddress);
                    })
                );
			}
		);
	}

	activateDeactivate() {
		this.activationService.activateDeactivate(
			this.id
			, ActivationEnum.Facility
			, this.formGroup.value.isActive ? false : true
		);
	}

	updateShippingInfo() {
		this.formGroup.controls.countyName.patchValue(this.formGroup.controls.smartyAddress.value.countyName);
		if (this.isSameAddress.checked) {
			this.formGroup.get("shipFacilityName").patchValue(this.formGroup.value.facilityName);
			this.formGroup.get("shipSmartyAddress").patchValue(this.formGroup.value.smartyAddress);
			this.toggleShippingFormActivation(false);
		} else {
			this.formGroup.get("shipFacilityName").patchValue(this.oldShipFacilityName);
			this.formGroup.get("shipSmartyAddress").patchValue(this.oldShipSmartyAddress);
			this.toggleShippingFormActivation(true);
		}
	}

	toggleShippingFormActivation(isActive: Boolean) {
		if (isActive) {
			this.enableShipFacilityName();
			this.shipAddressDisabled = false;
		} else {
			this.disableShipFacilityName();
			this.shipAddressDisabled = true;
		}
	}

	validateCountyId() {
		if (this.formGroup?.value?.countyId === 0) {
			this.formGroup?.controls?.countyId?.patchValue(null);
			this.model = EditFacilityVm.GetModel(this.formGroup);
		}
		this.formGroup.updateValueAndValidity();
	}

	submitForms() {	
        if (!this.formGroup.valid) {
			validateForm(this.formGroup);
			return;
		}

        if (
            this.oldIcqParticipant == true 
            && this.formGroup.controls.isIcqParticipant.value == false
        ) {
            const modal = this.modalService.open(ConfirmationModalComponent);
            modal.componentInstance.modalRef = modal;
            modal.componentInstance.confirmationMessage = "<p class='text-center'>" 
                + "Setting ICQ Participant to off will remove any published ICQ Surveys for this Fiscal Year assiciated to this facility." 
                + "</p>";
            modal.componentInstance.confirmationMessage += '<p class="text-center">Are you sure you wish to continue?</p>'
            modal.result.then((emittedValue) => {
                if (emittedValue) { return this.runSave(); }
            }).catch(err => { // When user clicks out of confirmation box.

            });
        }
		else { this.runSave(); }
	}

    runSave() {        
		let disabled = this.hasDisabledShipFacilityName();
		// As a result of moving this to a .ts POST method, disabled values
		// won't go through within the request body.
		if (this.hasDisabledShipFacilityName) { this.enableShipFacilityName(); }

        this.httpClient.post(
			`api/Administration/Facility/SaveFacility`
			, this.formGroup.value
		).subscribe((sm: SystemMessage) => {
			// Re-disable property after POST request.
			if (disabled) { this.disableShipFacilityName(); }
			if (sm.isSuccessful) { this.onMessageSuccess(sm); } 
			else { this.onMessageError(sm); }
		});
    }

	reroute(sm: SystemMessage) {
		if (
			this.businessArea === BusinessAreaEnum.Admin 
			|| this.businessArea === null 
			|| this.businessArea == undefined 
			|| Number.isNaN(this.businessArea)
		) {
			this.router.navigate([`/admin/organizations/facilities/edit/${sm.value}`]);
		} else if (this.businessArea === BusinessAreaEnum.CI) {
			this.router.navigate([`/ci-repack/facilities/edit/${sm.value}`]);
		} else if (this.businessArea === BusinessAreaEnum.MetabolicFormula) {
			this.router.navigate([`/metabolic-formula/facilities/edit/${sm.value}`]);
		}
	}

	onMessageSuccess(sm: SystemMessage) {
		this.setSysMessage(sm);
		this.formGroup.setValue(sm.model);
		this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => { this.reroute(sm); });
	}

	onMessageError(sm: SystemMessage) {
		this.setSysMessage(sm);
		this.formGroup.patchValue(sm.model);
	}

	enableShipFacilityName() {
		this.formGroup.get("shipFacilityName").enable();
		this.formGroup.get("shipSmartyAddress").enable();
	}

	disableShipFacilityName() {
		this.formGroup.get("shipFacilityName").disable();
		this.formGroup.get("shipSmartyAddress").disable();
	}

	medDirectorChange(select: PcgSelectComponent) {
		if (select.value) {
			this.model.medicalDirectorLicense = select.additionalData?.licenseNumber;
			this.model.medicalDirectorExpDate = select.additionalData?.expirationDate;
		} else {
			this.model.medicalDirectorLicense = "";
			this.model.medicalDirectorExpDate = "";
		}
	}

	setSysMessage = (sm: SystemMessage) => this.ms.setSystemMessage(sm.message, sm.messageClass);
	hasDisabledShipFacilityName = () => this.formGroup.get("shipFacilityName").disabled;
}
