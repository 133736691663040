<div class='modal-header'>
    <button 
        type='button' 
        class='close' 
        aria-label='Close' 
        (click)='activeModal.dismiss()'
    ><span aria-hidden='true'>&times;</span></button>
</div>
<div class='modal-body'>
    <div class='container'>
        <div class='text-center'>
            <img 
            class='img-fluid' 
            [src]='url' 
            [alt]="name + ' Photo'"
            />
        </div>
        <div class='bottom-buttons'>
            <button 
                class='btn btn-cancel btn-lg' 
                (click)='activeModal.dismiss()'
            >Close</button>
        </div>
    </div>
</div>
