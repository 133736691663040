import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getInvNav: () => NavRoute = () => {
	return {
		name: 'System Inventory'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.SysInv ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: getNavAreas()
	};
};

function getNavAreas(): NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf("inv/invs/products") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
	if (url.indexOf("inv/invs/replenishments") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
	if (url.indexOf("inv/invs/reconciliations") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
	if (url.indexOf("inv/invs/forms") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
	if (id.includes("?")) { id = FormatHelperService.replaceAfterChar(id, '?', ""); }

	return [
		{
			name: 'Products'
			, path: `inv/invs/products/${id}`
			, activeNavLink: 'inv/invs/products'
			, secondaryNav: ['System Inventory', 'Products']
		}
		, {
			name: 'Replenishments'
            , moduleAccess: [
                {
                    modules: [ BusinessAreaEnum.SysInv ]
                    , permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
                }
            ]
			, path: `inv/invs/replenishments/${id}`
			, activeNavLink: 'inv/invs/replenishments'
			, secondaryNav: ['System Inventory', 'Replenishments']
		}
		, {
			name: 'Reconciliations'
			, path: `inv/invs/reconciliations/${id}`
			, activeNavLink: 'inv/invs/reconciliations'
			, secondaryNav: ['System Inventory', 'Reconciliations']
		}
		, {
			name: 'Forms'
			, path: `inv/invs/forms/${id}`
			, activeNavLink: 'inv/invs/forms'
			, secondaryNav: ['System Inventory', 'Forms']
		}
	];
}
