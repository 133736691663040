import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';
import { getInvNav } from './inv-nav';
import { UserStateService } from 'app/state/user/user-state.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getInventoryNav: () => NavRoute = () => {
	const user = UserStateService.getCurrentUser();
    const icons = new FaBusinessAreaIconsService();    
	if (!user?.systemInventory?.length) { return; }

	const navRoute: NavRoute = {
		name: 'Inventory'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.SysInv ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, href: 'inv/invs/list'
		, children: [
			{
				name: 'System Inventory'
				, path: 'inv/invs/list'
				, childNav: getInvNav()
				, featureFlag: true
				, activeNavLink: 'inv/invs/list'
				, secondaryNav: ['Inventory', 'System Inventory' ]
			}
            , {
				name: 'Orders'
				, path: 'inv/form-orders/list'
				, featureFlag: true
				, activeNavLink: 'inv/form-orders'
				, secondaryNav: ['Inventory', 'Orders' ]
                , moduleAccess: [
                    {
                        modules: [ BusinessAreaEnum.SysInv ]
                        , permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
                    }
                ]
			}
			, {
				name: "EPCIS"
				, exact: true
				, path: "inv/epcis/list"
				, activeNavLink: 'inv/epcis/list'
				, secondaryNav: ['Inventory', 'EPCIS' ]
			}
			, {
				name: 'Admin'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.SysInv ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, children: [
					{
						name: 'Verification Settings'
						, exact: true
						, path: 'inv/admin/verification-settings'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.SysInv ]
								, permissionRoles: [ PermissionRole.SystemAdmin ]
							}
						]
						, activeNavLink: 'inv/admin/verification-settings'
						, secondaryNav: ['Admin', 'Verification Settings' ]
					} 
					, {
						name: 'Form Settings'
						, exact: true
						, path: 'inv/admin/form-settings'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.SysInv ]
								, permissionRoles: [ PermissionRole.SystemAdmin ]
							}
						]
						, activeNavLink: 'inv/admin/form-settings'
						, secondaryNav: ['Admin', 'Form Settings' ]
					}
					, {
						name: 'Fulfillment Groups'
						, exact: true
						, path: 'inv/admin/fulfillment-groups'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.SysInv ]
								, permissionRoles: [ PermissionRole.SystemAdmin ]
							}
						]
						, activeNavLink: 'inv/admin/fulfillment-groups'
						, secondaryNav: ['Admin', 'Fulfillment Groups' ]
					}
				]
			}
		]
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.SysInv)
		, area: 'inv'
		, module: BusinessAreaEnum.SysInv
	}

	return navRoute;

};