import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AI_DICT, AI_EXPIRATION_DATE, AI_GTIN, AI_LOT_NUMBER, AI_OPTIONAL_MAX_DICT, AI_SERIAL_NUMBER } from './ai-dicts';

import { SystemMessage } from 'app/core/system-message/system-message-service';
import { CurrentInventoryGfcVm } from 'app/shared/generated/Inventory/Models/CurrentInventoryGfcVm';
import { ProductInfoVm } from 'app/shared/generated/Inventory/Models/ProductInfoVm';
import { PatientDataVm } from 'app/shared/generated/Models/PatientDataVm';
import { IInventoryService } from './IInventoryService';
import { ProductBarcode } from './product-barcode';

@Injectable({
	providedIn: 'root'
})
export class InventoryService implements IInventoryService {

	constructor(private httpClient: HttpClient) { }

	// NDC Typeahead
	getNdcTypeahead = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(ndc => ndc.length < 5 
				? []
				: this.httpClient.get(`api/Select/GetGlobalNdcList?ndc=${encodeURIComponent(ndc)}`)
			)
		);
	}

	getNdc10Typeahead = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(ndc10 => ndc10.length < 5 
				? []
				: this.httpClient.get(`api/Select/GetGlobalNdc10List?ndc10=${encodeURIComponent(ndc10)}`)
					.pipe(map((response: any) => response.slice(0, 10)))
			)
		);
	}

	// API: api/Inventory/Inventory/GetProductInfoByNdc/
	getNdcProductInfo(
		component: any
		, ndc: string
		, inventorySiteId = null
	): Observable<ProductInfoVm> {
		return this.httpClient.get(this.formatNdcProductInfoRequest(ndc, inventorySiteId))
			.pipe(untilDestroyed(component)) as Observable<ProductInfoVm>;
	}

	formatNdcProductInfoRequest = (
		ndc: string
		, inventorySiteId: number | null = null
	): string => `api/Inventory/Inventory/GetProductInfoByNdc/?ndc=${encodeURIComponent(ndc)}&inventorySiteId=${encodeURIComponent(inventorySiteId)}`;

	getIdProductInfo(
		component: any
		, id: number
	): Observable<ProductInfoVm> { 
		return this.httpClient.get(this.formatIdProductInfoRequest(id))
			.pipe(untilDestroyed(component)) as Observable<ProductInfoVm>;
	}

	formatIdProductInfoRequest = (id: number): string => `api/Inventory/Inventory/GetProductInfo/?productId=${encodeURIComponent(id)}`;

	getInstockProductInfo(
		component: any
		, inventorySiteId: number
		, barcode: ProductBarcode
		, qtyPrescribed: number = null
		, isUser: boolean = false
	): Observable<ProductInfoVm> {
		console.log("isUser = " + isUser);
		return this.httpClient.get(
			this.formatInstockProductInfoRequest(
				inventorySiteId
				, barcode
				, qtyPrescribed
				, isUser
			)
		).pipe(untilDestroyed(component)) as Observable<ProductInfoVm>;
	}

	formatInstockProductInfoRequest = (
		inventorySiteId: number
		, barcode: ProductBarcode
		, qtyPrescribed: number = null
		, isUser: boolean = false
	): string => `api/Inventory/${isUser == true ? "RxFulfillmentUser" : "Inventory"}/GetInstockProductInfo/` 
		+ `?inventorySiteId=${inventorySiteId}` 
		+ `&ndc=${encodeURIComponent(barcode.productInfo?.ndc)}` 
		+ `&lotNumber=${barcode.lotNumber}` 
		+ `&expDateStr=${barcode.expirationDate?.toLocaleDateString()}` 
		+ `&serialNumber=${barcode.serialNumber}` 
		+ `&qtyPrescribed=${qtyPrescribed}`;

	// API: api/Inventory/Inventory/GetFastPackProductInfo/
	getFastPackProductInfo(
		component: any
		, inventorySiteId: number
		, ndc: string
		, barcodeStr: string
	): Observable<ProductInfoVm> {
		return this.httpClient.get(this.formatFastPackProductInfo(inventorySiteId, ndc, barcodeStr))
			.pipe(untilDestroyed(component)) as Observable<ProductInfoVm>;
	}

	formatFastPackProductInfo = (
		inventorySiteId: number
		, ndc: string
		, barcodeStr: string
	): string => `api/Inventory/Inventory/GetFastPackProductInfo/` 
		+ `?inventorySiteId=${inventorySiteId}` 
		+ `&ndc=${ndc}` 
		+ `&barcodeStr=${barcodeStr}`

	getProductId(
		component: any
		, inventorySiteId: number
		, ndc: string
		, isUser: boolean = false
	): Observable<ProductInfoVm> {
		return this.httpClient.get(
			`api/Inventory/${isUser == true ? "RxFulfillmentUser" : "Inventory"}/GetInstockProductInfo/` 
			+ `?inventorySiteId=${inventorySiteId}` 
			+ `&ndc=${encodeURIComponent(ndc)}`
		).pipe(untilDestroyed(component)) as Observable<ProductInfoVm>;
	}

	getSiteName(
		component: any
		, inventorySiteId: number
	): Observable<string> {
		return this.httpClient.get<string>(`api/Inventory/Inventory/GetInventorySiteName/${inventorySiteId}`)
			.pipe(untilDestroyed(component));
	}

	getCurrentStock(
		component: any
		, inventorySiteId: number
		, ndc: string
		, lotNum: string
		, expDate: Date
		, serialNum: string
	): Observable<ProductInfoVm> {
		if (serialNum === null) { serialNum = ''; }
		return this.httpClient.get(`api/Inventory/Inventory/GetCurrentStock/
			?inventorySiteId=${inventorySiteId}
			&ndc=${ndc}
			&lotNum=${lotNum}
			&expDate=${expDate}
			&serialNum=${serialNum}`
		).pipe(untilDestroyed(component)) as Observable<ProductInfoVm>;
	}

	getCurrentInventoryByGfc(
		component: any
		, inventorySiteId : number
		, isActive : boolean
		, needsOrdering : boolean
	) : Observable<CurrentInventoryGfcVm> {
		return this.httpClient.get<CurrentInventoryGfcVm>(`api/Inventory/Inventory/GetCurrentInventoryByGfc/
			?inventorySiteId=${inventorySiteId}
			&isActive=${isActive}
			&needsOrdering=${needsOrdering}`
		).pipe(untilDestroyed(component)) as Observable<CurrentInventoryGfcVm>
	}

	// Validate that the product has not been previously returned.
	validateRxReturn(
		component: any
		, inventorySiteId: number
		, ndc: string
		, inventoryProductId: number
		, lotNum: string
		, expDate: Date
		, serialNum: string
	): Observable<SystemMessage> {
		if (serialNum === null) { serialNum = ''; }
		return <Observable<SystemMessage>>this.httpClient
			.get(`api/Inventory/Inventory/ValidateRxReturn/
				?inventorySiteId=${inventorySiteId}
				&ndc=${ndc}
				&inventoryProductId=${inventoryProductId}
				&lotNum=${lotNum}
				&expDate=${expDate}
				&serialNum=${serialNum}`
			).pipe(untilDestroyed(component));
	}

	getPatientInfo(
		component: any
		, barcodeStr: string
		, shipmentId: number
		, scanned: boolean
		, isUser: boolean = false
	): Observable<any> {
		return <Observable<any>>this.httpClient
			.get(
				`api/Inventory/QS1/GetRxData/` 
				+ `?rxNumber=${encodeURIComponent(barcodeStr)}` 
				+ `&shipmentId=${encodeURIComponent(shipmentId)}` 
				+ `&scanned=${encodeURIComponent(scanned)}`
				+ `&isUser=${encodeURIComponent(isUser)}`
			).pipe(untilDestroyed(component));
	}

	getPatientInfoNoShipment(
		component: any
		, barcodeStr: string
		, isFacility: boolean
		, scanned: boolean
		, isUser: boolean = false
	): Observable<PatientDataVm> {
		return <Observable<PatientDataVm>>this.httpClient
			.get(
				`api/Inventory/QS1/GetRxData/` 
				+ `?rxNumber=${encodeURIComponent(barcodeStr)}` 
				+ `&isFacility=${isFacility}` 
				+ `&scanned=${encodeURIComponent(scanned)}`
				+ `&isUser=${encodeURIComponent(isUser)}`
			).pipe(untilDestroyed(component));
	}

	/**
	 * The main function that will be called from the application to get the barcode data
	 * @param barcode The barcode to parse
	 * @param delimiter The delimeter to use when parsing.
	 * The function will attempt to guess the delimiter if it is not provided.
	*/
	scanGs1Barcode(
		barcode: string
		, delimiter: string = null
	) {
		if (barcode.substr(0, 3) === 'PFS') { return this.getProductBarCodeFromPfsData(barcode); }
		if (
			barcode === null 
			|| typeof barcode === 'undefined' 
			|| barcode.length < 20
		) { return null; }
		if (delimiter !== null) {
			return this.getProductBarCodeFromGs1Data(this.parseGs1Barcode(delimiter !== '↔' ? barcode.replace(/↔/g, '') : barcode, delimiter));
		}

		let gs1Data = null;
		const guessedDelimiters = [];
		// Make up to 10 guesses on the delimiter
		for (let i = 0; i < 10; ++i) {
			let delimiterGuess = this.guessGs1Delimiter(barcode, guessedDelimiters);
			gs1Data = this.parseGs1Barcode(delimiterGuess !== '↔' ? barcode.replace(/↔/g, '') : barcode, delimiterGuess);
			guessedDelimiters.push(delimiterGuess);

			// Return data if we make a good guess
			if (gs1Data !== null) { return this.getProductBarCodeFromGs1Data(gs1Data); }
		}

		// Return null if we failed to parse the barcode
		return null;
	}

	private getProductBarCodeFromPfsData(gs1Data: any): ProductBarcode {
		if (gs1Data === null) { return gs1Data; }
		let array = gs1Data.split('|');
		return {
			ndc10: this.parseNdc10FromGtin(array[1]),
			lotNumber: array[3],
			serialNumber: null,
			expirationDate: new Date(+array[2].substr(4, 4), +array[2].substr(0, 2) - 1, +array[2].substr(2, 2)),
			isPfs: true
		};
	}

	/**
	 * Creates a friendly object based on the parsed GS1 barcode
	 * @param gs1Data An object mapping application identifiers to their values, generated by parseGs1Barcode
	*/
	private getProductBarCodeFromGs1Data(gs1Data: any): ProductBarcode {
		if (gs1Data === null) { return gs1Data; }
		var expDateStr = gs1Data[AI_EXPIRATION_DATE];
		var last2 = expDateStr?.slice(-2);
		if (last2 === "00") { expDateStr = this.convertZeroZeroToEndOfMonth(expDateStr); }
		// Per GS1 documentation, exp date is formatted 'YYMMDD'
		// Date strings should be formatted 'MM DD YY' before being passed into Date.parse()
		let formatExpDateStr = `${expDateStr?.slice(2,4)} ${expDateStr?.slice(4)} ${expDateStr?.slice(0,2)}`;
		let timestamp = Date.parse(formatExpDateStr);
		if (isNaN(timestamp)) { return gs1Data; }
		return {
			ndc10: this.parseNdc10FromGtin(gs1Data[AI_GTIN])
			, lotNumber: gs1Data[AI_LOT_NUMBER].toUpperCase()
			, serialNumber: gs1Data[AI_SERIAL_NUMBER]
			, expirationDate: new Date(
				+expDateStr?.substr(0, 2) + 2000
				, +expDateStr?.substr(2, 2) - 1
				, +expDateStr?.substr(4, 2)
			)
			, gtin: gs1Data[AI_GTIN]
		};
	}

	convertZeroZeroToEndOfMonth(expDate: string) {
		var lastDay = new Date(+expDate?.substr(0,2) + 2000, +expDate?.substr(2,2), 0);
		var newDate = expDate?.substr(0,2) + expDate?.substr(2,2) + lastDay.getDate().toString();
		return newDate;
	}

	/**
	 * Attempt to guess the GS1 delimiter based on the barcode, in case we don't have a way of knowing it.
	 * A null value will be returned if no possible values are found
	 * @param barcode The barcode to parse
	 * @param excludedChars Characters that have already been tried. These will not be returned.
	*/
	private guessGs1Delimiter(
		barcode: string
		, excludedChars: string[] = []
	) {
		// Return null if we do not have a string
		if (
			barcode === null 
			|| typeof barcode === 'undefined' 
			|| barcode.length === 0
		) { return null; }

		// Filter out characters that have already been guessed
		const bcChars = barcode?.split('').filter(o => !excludedChars.includes(o));

		// Return null if all characters have been guessed
		if (bcChars.length === 0) { return null; }

		// Return the first character if it is not a number
		if (isNaN(+bcChars[0])) { return bcChars[0]; }

		// Check for double arrow
		if (bcChars.includes('↔')) { return '↔'; }

		// Look for characters outside of the extended ASCII range
		let nonAsciiChars = bcChars.filter(o => o > '\u00FF');
		if (nonAsciiChars.length > 0) { return nonAsciiChars[0]; }

		// Look for characters outside of the standard ASCII range
		nonAsciiChars = bcChars.filter(o => o > '\u007F');
		if (nonAsciiChars.length > 0) { return nonAsciiChars[0]; }

		// Look for any other non-alphanumeric characters
		const nonAlphanumericChars = bcChars.filter(o => o < '0' || (o > '9' && o < 'A') || (o > 'Z' && o < 'a') || o > 'z');
		if (nonAlphanumericChars.length > 0) { return nonAlphanumericChars[0]; }

		// Otherwise, just return something
		return bcChars[0];
	}

	/**
	 * Get the 10-digit NDC, without dashes, from the 12 or 14 digit GTIN
	 * @param gtin GTIN is parsed from either the GS1 barcode (14-digit), or is
	 * the value gotten from the traditional barcode (12-digit).
	 * A null value will be returned if GTIN is not a valid length.
	*/
	parseNdc10FromGtin(gtin: string) {
		// Return null if we don't have a GTIN
		if (
			gtin === null 
			|| typeof gtin === 'undefined'
		) { return null; }

		// Parse 11-digit GTIN (used when scanning Homer repack labels which contain regular NDC without "-". This returns a full NDC rather than NDC10..
		// Move to its own method or change the method name/ndc10 property name on Product Barcode model to avoid confusion?)
		if (gtin.length === 11) { return gtin.slice(0, 5) + "-" + gtin.slice(5, 9) + "-" + gtin.slice(9, 11); }

		// Parse 12-digit GTIN
		if (gtin.length === 12) { return gtin.substr(1, 10); }

		// Parse 14-digit GTIN
		if (gtin.length === 14) { return gtin.substr(3, 10) }

		// Otherwise, return null
		return null;
	}

	/**
	 * Scan the GS1 barcode and return an object mapping its application identifiers to
	 * their values in the barcode.
	 * A null value will be returned if the barcode is not in a valid format.
	 * @param barcode The barcode to parse
	 * @param delimiter The delimeter to use for the barcode's variable length fields
	*/
	parseGs1Barcode(
		barcode: string
		, delimiter: string
	) {
		let parsingAi = true; // bool indicating whether or not we are currently parsing an application identifier
		let ai = ''; // current application identifier value
		let val = ''; // current parsed value
		let parseObj = {}; // this will map discovered application identifiers to their values

		// Loop through the barcode string
		for (let i = 0; i < barcode.length; ++i) {
			// Look for the application identifier, if we don't have one already
			if (parsingAi) {
				if (barcode[i] !== delimiter) { ai += barcode[i]; }
				// We found a valid application identifier, so stop parsing it
				if (typeof AI_DICT[ai] !== 'undefined') { parsingAi = false; }
				continue;
			}

			// After we have an application identifier, parse its corresponding value
			// Stop parsing value if we exceed max number of characters for string or hit the delimiter
			if (
				AI_DICT[ai] === val.length 
				|| (
					AI_OPTIONAL_MAX_DICT[ai] 
					&& val.length >= AI_OPTIONAL_MAX_DICT[ai]
				) 
				|| barcode[i] === delimiter
			) {
				parseObj[ai] = val;
				parsingAi = true;
				val = '';
				ai = '';
				--i;
			} else { val += barcode[i]; }
		}

		// Set the value that was last in the barcode
		if (typeof AI_DICT[ai] !== 'undefined') {
			parseObj[ai] = val;
		} else if (ai.length > 0) { // Invalid value at the end of barcode, return null to indicate error 
			return null;
		}

		return parseObj;
	}
}
