import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class QuickNavService {
    // Controls whether projected content is shown or hidden
    showContent = signal<boolean>(false);
    // Prevents content from being closed, useful for ngbTypeahead clicks
    preventCloseContent = signal<boolean>(false);

    toggleContent() {
        this.showContent.update((showContent) => !showContent);
    }

    closeContent() {
        this.preventCloseContent.set(false);
        this.showContent.set(false);
    }
}