import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';

export const getNdcDirectoryNav: () => NavRoute = () => {

	const user = getUser();
    const icons = new FaBusinessAreaIconsService();
	var navItems: NavRoute[] = [
		{
			name: 'FDA'
			, exact: true
			, path: 'ndcs/fda/list'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, activeNavLink: 'ndcs/fda'
			, secondaryNav: ['NDCs', 'FDA']
		}
        , {
			name: 'NDC Lookup'
			, exact: true
			, path: 'ndcs/lookup'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, activeNavLink: 'ndcs/lookup'
			, secondaryNav: ['NDCs', 'NDC Lookup']
		}
        , {
			name: 'Recalls'
			, exact: true
			, path: 'ndcs/recalls/list'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, activeNavLink: 'ndcs/recalls'
			, secondaryNav: ['NDCs', 'Recalls']
		}
		, {
			name: 'Redbook'
			, exact: true
			, path: 'ndcs/redbook/list'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, activeNavLink: 'ndcs/redbook'
			, secondaryNav: ['NDCs', 'Redbook']
		}
	];

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.NDC)) {
		navItems.push({
			name: 'Error Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.ErrorReport ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, exact: true
			, path: `error-report/error-reports/ndcs/list/${BusinessAreaEnum.NDC}`
			, activeNavLink: 'error-report/error-reports/ndcs'
			, secondaryNav: ['NDCs', 'Error Reporting']
		});
	}

	if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.NDC)) {
		navItems.push({
			name: 'Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Reports ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, exact: true
			, path: `reporting/list/${BusinessAreaEnum.NDC}`
			, activeNavLink: 'reporting/list'
			, secondaryNav: ['NDCs', 'Reporting']
		});
	}

	return {
		name: 'NDCs'
		, href: 'ndcs/redbook/list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.NDC ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: navItems
		, icon: icons.getIconByBusinessArea(BusinessAreaEnum.NDC)
		, area: 'ndcs'
		, module: BusinessAreaEnum.NDC
	};
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}