import { Injectable } from '@angular/core';

import { PatientDataVm } from 'app/shared/generated/Models/PatientDataVm';

@Injectable({ providedIn: 'root' })
export class RxDataStorageService {

constructor() { }

	//Properties used to temporarily store and pass data between modules for the inventory choice modal
	PatientData: PatientDataVm;
	IsFacility: boolean;

	//Need a function that will reset the data stored in the service between sessions
	resetInventoryChoiceModalData(){
		this.PatientData = null;
		this.IsFacility = null;
	}
}
