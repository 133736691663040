import { EventEmitter, inject, Injectable } from '@angular/core';
import { SecurityService } from '../security/security.service';
import { HttpClient } from '@angular/common/http';
import { SseNotificationDataVm } from 'app/shared/generated/Models/SseNotificationDataVm';

@Injectable({
  providedIn: 'root'
})
export class SseNotificationService {
    sec = inject(SecurityService);
    http = inject(HttpClient);
    notificationSource: EventSource;
    notificationReceived = new EventEmitter<SseNotificationDataVm>();

    initializeSSE() {
        let userId = this.sec.getUser()?.id;
        if (userId  && (!this.notificationSource || this.notificationSource.readyState === EventSource.CLOSED)) {
            this.notificationSource = new EventSource(`/api/sse-notif/${userId}`);
            this.setupEventListeners();
        }        
    }

    destroySSE() {
        let userId = this.sec.getUser()?.id;
        if (userId && this.notificationSource){ 
            this.notificationSource.close(); 
            if(this.sec.getUser()) this.http.post(`/api/sse-notif/remove/${userId}`, null).subscribe();
        }       
    }

    private setupEventListeners() {
        this.notificationSource.removeAllListeners();
        this.notificationSource.onopen = () => {
            console.log('SSE notification connection opened');
        };

        this.notificationSource.addEventListener('notification', (event: MessageEvent) => {
            const data: SseNotificationDataVm = JSON.parse(event.data);
            this.notificationReceived.emit(data);
        });

        this.notificationSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            this.destroySSE();
        };
    }
}
