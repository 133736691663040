<ng-container *ngIf='!newModel.actionsSelected else accountUpdate'>
	<pcg-table-filter id='tblFilters'>
		<div (change)="table.ajaxReload();">
			<div class="additional-info mt-1">
				<div class='mt-2 filter-width'>
					<label class='for'>Is Active?</label>
					<mat-button-toggle-group 
						[(ngModel)]='filters.isActive' 
						[value]='filters.isActive'
						(change)="table.ajaxReload();"
					>
						<mat-button-toggle 
							class="flex-fill" 
							[value]="true"
						>Yes</mat-button-toggle>
						<mat-button-toggle 
							class="flex-fill mx-2" 
							[value]="false"
						>No</mat-button-toggle>
						<mat-button-toggle 
							class="flex-fill" 
							value="null"
						>All</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
		</div>
	</pcg-table-filter>

	<div id="informativeContent">
		<div id="actionElements">
			<button 
				[disabled]="this.newModel.actionsSelected" 
				class="btn btn-save btn-sm inline-add-btn"
				(click)="actionClick()"
			>Create Organization Account</button>
		</div>
	</div>

	<pcg-table 
		#table 
		[ajaxData]='filters' 
		[multipleSearch]='false' 
		[pcgSort]='[[ "facilityAccountNameStr", "asc" ]]'
		[filterMap]="btnFilterService.filterMap" 
		dataSource='api/Administration/OrganizationAccount/GetList'
		[columnDefs]='columns' 
		filterId="tblFilters" 
		[canShowHideColumns]="true" 
		[canExactMatchSearch]="false"
		[canResetFilters]="false"
	>

		<ng-container matColumnDef='actions'>
			<th 
				class='text-center' 
				mat-header-cell 
				*matHeaderCellDef
			>{{ columns.get('actions').displayName }}</th>
			<td 
				class='text-center' 
				mat-cell 
				*matCellDef='let row'
			>
				@if (!row.actionsSelected) {
					<div 
						class="actions-td" 
						(click)="actionClick(row)"
					>{{ row.actions }}</div>
				}
			</td>
		</ng-container>

		<ng-container matColumnDef='facilityAccountNameStr'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('facilityAccountNameStr').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>
				{{ row.facilityAccountNameStr }}
				@if (
					row.hasPendingRequest 
					&& !row.isNewRequest
				) {
					<span 
						class="pending"
						ngbTooltip='This Organization Account has a pending update request.'
					>Pending Update</span>
				}
				@if (row.isNewRequest) {
					<span 
						class="new" 
						ngbTooltip='This is a newly added Organization Account and must be approved before being used.'
					>New</span>
				}
			</td>
		</ng-container>

		<ng-container matColumnDef='accountNumber'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('accountNumber').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>{{ row.accountNumber }}</td>
		</ng-container>

		<ng-container matColumnDef='expirationDateStr'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('expirationDateStr').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>{{ row.expirationDateStr }}</td>
		</ng-container>

		<ng-container matColumnDef='isActive'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('isActive').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>
				@if (row.isActive) { <span>Yes</span> }
				@else { <span>No</span> }
			</td>
		</ng-container>

		<ng-container matColumnDef='lastUpdatedDateStr'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('lastUpdatedDateStr').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>{{ row.lastUpdatedDateStr }}</td>
		</ng-container>

		<ng-container matColumnDef='lastUpdatedBy'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('lastUpdatedBy').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>{{ row.lastUpdatedBy }}</td>
		</ng-container>

		<ng-container matColumnDef='uploadFilePath'>
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('uploadFilePath').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row;'
			>
				<a 
					(click)="openFile(row?.uploadFilePath)" 
					href="javascript:void(0)"
				>{{ row.fileName }}</a>
			</td>
		</ng-container>
	</pcg-table>

</ng-container>

<ng-template #accountUpdate>
	<form 
		(submit)='editSave()' 
		[formGroup]='formGroup' 
		class="edit-form"
	>
		<input type='hidden' formControlName='organizationAccountId' />
		<input type='hidden' formControlName='facilityAccountNamePfsId' />
		<input type='hidden' formControlName='pfsFacilityAccountIds' />

		<div class="row">
			<pcg-control-group class="col-md-6">
				<label class="required-field for">Account Name</label>
				<pcg-select 
					#select 
					[sendFormData]='false' 
					pcgControlRef 
					[multiple]='false'
					dataSource='api/Select/FacilityAccountNames' 
					formControlName='facilityAccountNameId'
					placeholder="Select ..." 
					(change)="
						accountNameClicked(); 
						setExpDateRequired();
					"
				></pcg-select>
			</pcg-control-group>

			<pcg-control-group class="col-md-2">
				<label class="required-field for">Account #</label>
				<input 
					pcgControlRef 
					formControlName="accountNumber" 
					class='form-control' 
					type='text' 
					maxlength="50"
					pattern="[A-Za-z0-9 ]*" 
				/>
			</pcg-control-group>

			<pcg-control-group class="col-md-2">
				<label 
					[class.required-field]='expDateRequired' 
					class='for mb-0'
					ngbTooltip="Enter an expiration date (if required). This requirement can be changed by admins via the 'Expiration Notifications' 
					toggle in Admin > Facilities > Master Accounts"
				>Expiration Date</label>
				<input 
					type="date" 
					pcgControlRef 
					formControlName="expirationDate" 
					placeholder="MM/dd/yyyy"
					class="form-control" 
					maxlength="10"
                    [ngModel]="formGroup.controls.expirationDate.value | date:'yyyy-MM-dd'" 
					(change)="updateExpStr()" 
				/>
			</pcg-control-group>

			<pcg-control-group class='col-md-1'>
				<label 
					class='for' 
					ngbTooltip="Select if your facility is requesting a Grace Period to allow the account to continue to function."
				>Grace Period</label>
				<ui-switch 
					pcgControlRef 
					color='#007bff' 
					defaultBgColor='#EEE' 
					class='ml-2 mt-2' 
					size='small'
					formControlName='isGracePeriod'
				></ui-switch>
			</pcg-control-group>

			<pcg-control-group class="col-md-1">
				<label class='for'>Is Active</label>
				<ui-switch 
					pcgControlRef 
					color='#007bff' 
					defaultBgColor='#EEE' 
					class='ml-2 mt-2' 
					size='small'
					formControlName='isActive'
				></ui-switch>
			</pcg-control-group>
		</div>

		<div class="row">
			<pcg-control-group class="col-md-6">
				@if (!hasFile) {
					<pcg-drag-drop-file-upload 
						labelStr='Related Documents' 					
						[fileList]="facilityFile" 
						[(formGroup)]="formGroup"
						[formGroupControlName]="'uploadFiles'" 
						[maxFiles]="maxFiles" 
						[minHeightPixels]="200"
						[minWidthPixels]="200" 
						[allowedFilesMessage]="'(Optional: up to 1 image or pdf)'"
						[allowedFiles]="'pdf, images:'"
					></pcg-drag-drop-file-upload>
				} @else {
					<div class="item">
						<a 
							target="_blank" 
							[href]="formGroup.controls.uploadFilePath.value"
						>{{formGroup.controls.fileName.value}}</a>
						<span>&nbsp;&nbsp;&nbsp;</span>
						<div 
							class='btn btn-delete btn-sm' 
							(click)="clearFile()"
						>X</div>
						<input type="hidden" formControlName="uploadFilePath" />
					</div>
				}
			</pcg-control-group>

			<pcg-control-group class="col-md-6">
				<label> </label>
				<label class='for'>Notes</label>
				<textarea 
					pcgControlRef 
					class='form-control notes' 
					formControlName='notes' 
					maxlength="1000"
					rows="7"
				></textarea>
			</pcg-control-group>
		</div>
		<br />

		<div class='bottom-buttons'>
			<button 
				type="button" 
				class="btn btn-cancel btn-lg" 
				(click)="cancel()"
			>Cancel</button>
			<input 	
				type="submit" 
				class='btn btn-save btn-lg' 
				value="Submit Update Request" 
				[disabled]="saveBtnDisabled" 
				[ngbTooltip]="saveBtnTooltip"
			/>
		</div>
	</form>
</ng-template>

<ng-template 
	#accountUpdateOptions 
	let-modal 
	class='facility-accounts-actions'
>
	<div class='modal-body'>
		<div class="bold text-center border-bottom p-3 align-middle">{{ newModel.facilityAccountNameStr }}</div>
		@if (
			!editClicked 
			&& !deleteClicked
		) {
			<div>
				@if (canEdit) {
					<div 
						class="bold text-center border-bottom action p-3 align-middle" 
						(click)="editClick()"
					>Request Update</div>
				}
				@if (canDelete) {
					<div 
						class="bold text-center border-bottom action-delete p-3 align-middle" 
						(click)="deleteClick()"
					>Delete</div>
				}
				<div 
					class="bold text-center action p-3 align-middle" 
					(click)="cancel()"
				>Cancel</div>
			</div>
		}
		@if (deleteClicked) {
			<div>
				<div class="alert alert-danger text-center">Are you sure you want to delete this Organization Account?</div>
				<div class='bottom-buttons'>
					<button 
						type="button" 
						class="btn btn-cancel btn-lg" 
						(click)="cancel()"
					>Cancel</button>
					<input 
						type="button" 
						class='btn btn-save btn-lg' 
						value="Delete" 
						(click)="performDelete()" 
					/>
				</div>
			</div>
		}
	</div>
</ng-template>
