<div class="modal-header bg-white rounded">
	<h5 
        class="modal-title" 
        id="modal-basic-title"
    >Prescriptions</h5>
	<h5 
        *ngIf="rxList[0]?.pfsNumber" 
        class="modal-title justify-self-center"
    >&nbsp;|&nbsp;{{ rxList[0].pfsNumber }}</h5>
	<button 
        type="button" 
        class="close" 
        aria-label="Close" 
        (click)="modal.dismiss()"
    >
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="row">
		<div 
            class="col-md-4" 
            style="text-align: left;"
        >
            <fa-icon 
                [icon]="faEOS" 
                class="mr-2"
            ></fa-icon> = EOS
        </div>
		<div 
            class="col-md-4" 
            style="text-align: center;"
        >
            <fa-icon 
                [icon]="faEarlyRx" 
                class="mr-2"
            ></fa-icon> = Early Refill
        </div>
		<div 
            class="col-md-4" 
            style="text-align: right;"
        >
            <fa-icon 
                [icon]="faTransfer" 
                class="mr-2"
            ></fa-icon> = Transfer
        </div>
	</div>
	<br />

	<table 
        *ngIf="!isEmpty" 
        class="data-grid"
    >
		<thead>
			<th>Rx</th>
			<th *ngIf="!isUser">First Name</th>
			<th *ngIf="!isUser">Last Name</th>
			<th>Product</th>
			<th>PFS Flags</th>
		</thead>
		<tbody *ngFor="let row of rxList">
			<tr>
				<td>{{ row.rxNumber }}</td>
				<td *ngIf="!isUser">{{ row.patientFirstName }}</td>
				<td *ngIf="!isUser">{{ row.patientLastName }}</td>
				<td>{{ row.productName }}</td>
				<td>
					<fa-icon 
                        *ngIf="row.isEos" 
                        [icon]="faEOS" 
                        class="mr-2"
                    ></fa-icon>
					<fa-icon 
                        *ngIf="row.isEarlyRx" 
                        [icon]="faEarlyRx" 
                        class="mr-2"
                    ></fa-icon>
					<fa-icon 
                        *ngIf="row.isTransfer" 
                        [icon]="faTransfer" 
                        class="mr-2"
                    ></fa-icon>
				</td>
			</tr>
		</tbody>
	</table>

	<p *ngIf="isEmpty">There are no Rx's assigned to this fulfillment.</p>
</div>