import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'pcg-photo-modal',
    templateUrl: './photo-modal.component.html',
    styleUrls: ['./photo-modal.component.scss'],
    standalone: false
})
export class PhotoModalComponent implements OnInit {

    url: string; // The URL of the image we want to display
	name: string; // The name of the photo

	constructor(public activeModal: NgbActiveModal) { }

	ngOnInit() { }
}
