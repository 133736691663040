import { computed, Injectable, signal } from "@angular/core";
import { toObservable } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from "@angular/router";
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap } from "rxjs/operators";

import { getMainNavRoutes } from "app/core/main-nav-routes";
import { SecurityService } from "app/core/security/security.service";
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { FormatHelperService } from "../helpers/format-helper.service";
import { GlobalService } from "../services/global.service";
import { NavRoute } from "./nav.route.interface";

export const NavDividerCharacter = ">>";

const rootNav = { name: "Root", id: "root" }
const pcgNav = { name: "PCG", id: "pcg" }
/**
* The `NavigationService` class is responsible for managing the navigation state and behavior in the application. It handles the following:

* - Maintaining the current state of the primary, secondary, and side navigation menus.
* - Subscribing to route changes and updating the navigation state accordingly.
* - Providing methods to set the active navigation menu, open secondary and side menus, and toggle the navigation state.
* - Flattening the navigation routes for easier filtering and searching.
* - Determining whether the current user has access to view the navigation and its sub-navigation.
* - Parsing the current URL and updating the selected navigation item.
*/
@UntilDestroy()
@Injectable({ providedIn: "root" })
export class NavigationService {

	selectedMainNav = signal<string>(null);
	isNavOpen = signal<boolean>(false);
    isNotificationsNavOpen = signal<boolean>(false);
	isNavFixed = signal<boolean>(false);
	isMobile = signal<boolean>(false);
	userProfileExpanded = signal<boolean>(false);

	// The currently OPEN primary navigation menu
	currOpenPrimaryNavMenu = signal<string>("");
	currOpenPrimaryNavMenu$ = toObservable(this.currOpenPrimaryNavMenu);

	// The currently OPEN secondary navigation menu
	currOpenSecondaryNavMenu = signal<NavRoute>(rootNav);
	currOpenSecondaryNavMenu$ = toObservable(this.currOpenSecondaryNavMenu);

	// The currently OPEN side navigation menu
	currOpenSideNavMenu = signal<NavRoute>(rootNav);
	currOpenSideNavMenu$ = toObservable(this.currOpenSideNavMenu);

	// The currently SELECTED navigation menu
	currSelectedMainNavMenu = signal<NavRoute>(rootNav);
	currSelectedMainNavMenu$ = toObservable(this.currSelectedMainNavMenu);

	// Force select an item in nav
	currForceSelect = signal<string>(null);
	currForceSelect$ = toObservable(this.currForceSelect);

	navRoutes = signal<NavRoute[]>(getMainNavRoutes());
	navRoutes$ = toObservable(this.navRoutes);

	currNav = signal<NavRoute>(null);
	
	/**
	 * Computes the dynamic navigation title based on the current navigation route.
	 * If the current navigation route is 'Pharmacy Inventory', it returns the value from `this.gvs.pharmInvName()`.
	 * If the current navigation route is 'System Inventory', it returns the value from `this.gvs.invName()`.
	 * Otherwise, it returns the name of the current navigation name.
	*/
	dynamicNavTitle = computed(() => {
		switch (this.currNav()?.name) {
			case 'Pharmacy Inventory': return this.gvs.pharmInvName();
			case 'System Inventory': return this.gvs.invName();
			default: return FormatHelperService.GetIsNullyOrWhitespace(this.currNav()?.name) ? 'HOMER' : this.currNav().name;
		}
	});

	lastPageInitiatedMenu = signal<string[]>(null);

	/** Used to address wonky behavior with rapid mouse movements. */
	private _isNavOpen$ = new Subject<boolean>();
	
	constructor(
		router: Router
		, private sec: SecurityService
		, public gs: GlobalService
		, private gvs: GlobalVariablesService
		, title: Title
	) {
		// Subscribe to route changes and update the navs when they happen
		router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (sec.isLoggedOn()) {
					setNavRoutes();
					this.parseUrl(event.url);
					setTitle();
				}
			});

		// Set a descriptive title for the page instead of the default "HOMER" title when one is not set.
		const setTitle = () => {
			if (title.getTitle() === "HOMER" || FormatHelperService.GetIsNullyOrWhitespace(title.getTitle())) {
				title.setTitle(this.dynamicNavTitle() ?? "HOMER");
			}
		};

		// Update nav routes on security and user updates
		const setNavRoutes = () => {
			// Set nav routes
			const routes = sec.getSecureNavItems(getMainNavRoutes());
			this.navRoutes.set(routes);
			// Try to reload current nav to fix children
			const currNav = this.currSelectedMainNavMenu();
			const newSelectedMainNavMenu = this.getFlatMenu(routes).find((o) => o.id === currNav?.id);
			if (newSelectedMainNavMenu) { this.currSelectedMainNavMenu.set(newSelectedMainNavMenu); }
		};
		sec.user$.subscribe(setNavRoutes);

		// Used to address wonky behavior with rapid mouse movements.
		this._isNavOpen$.pipe(
			debounceTime(this.isNavOpen() ? 0 : 100), // Close instantly, open with delay
			switchMap(async (event?: boolean) => this.toggleNavOpenDebounce(event))
		).subscribe();

		this.isNavFixed.set(localStorage.getItem("isNavFixed") == "true");
	}

	/**
	 * Set the currently open navigation menu.
	 * On pages, use setOpenSecondaryMenu instead.
	 * @param newMenu The current main nav menu as a dot separated string. Always starts with
	 * root. For example, 'root>>Style Guide>>Templates'
	 * @param isSecondary Whether or not to open secondary menu instead of primary menu
	 * @param isSideNav Whether or not to open side menu instead of primary menu
	*/
	setCurrOpenNavMenu(
		newMenu: string
		, isSecondary: boolean = false
		, isSideNav: boolean = false
	) {
		if (
			!FormatHelperService.GetIsNullyOrWhitespace(newMenu) 
			&& (
				isSecondary 
				|| isSideNav
			)
		) {
			const nextNav = this.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes())).find((nav) => nav.id === newMenu);
			if (isSecondary) { this.currOpenSecondaryNavMenu.set(nextNav); } 
			else if (isSideNav) { this.currOpenSideNavMenu.set(nextNav); }
		} else { this.currOpenPrimaryNavMenu.set(newMenu); }
	}

	/** Used to set active menu for pages */
	setActiveNav(newMenu: string[]) { this.setOpenSecondaryMenu(newMenu); }

	/** Used to set secondary menu for pages */
	setOpenSecondaryMenu(newMenu: string[]) {
		// suggests the tab directive emitted with a newMenu object
		// without restarting the component's lifecycle
		if (newMenu?.length === 0) { newMenu = this.lastPageInitiatedMenu(); }
		// suggests a *.component.ts file is pushing a newMenu into state (when it's not null!)
		if (
			!FormatHelperService.GetIsNully(newMenu) 
			&& newMenu?.length > 1
		) { this.lastPageInitiatedMenu.set(newMenu); }
		const menuList = this.getMenuNameWithRoot(newMenu);
		const flatMenu = this.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes()));
		// fix: hidden TypeError
		let menuId = !menuList ? "" : menuList.join(NavDividerCharacter);

		if (!FormatHelperService.GetIsNullyOrWhitespace(menuId)) {
			const menuItem = flatMenu.find((o) => o.id === menuId);
			if (
				menuItem?.path 
				&& newMenu?.length > 0
			) {
				this.currForceSelect.set(menuId);
				menuId = menuList.splice(0, newMenu.length).join(NavDividerCharacter);
			} else { this.currForceSelect.set(null); }
			this.setCurrOpenNavMenu(menuId, true);
		}
	}

	/** Used to set side menu for pages */
	setOpenSideMenu(newMenu: string[]) {
		const menuList = this.getMenuNameWithRoot(newMenu);
		this.setCurrOpenNavMenu(menuList.join(NavDividerCharacter), false, true);
	}

	/** Return a flattened menu that can more easily be filtered and searched */
	getFlatMenu(
		routes: NavRoute[]
		, id = "root"
		, parentNav: NavRoute = null
	): NavRoute[] {
		let flatRoutes = [];
		// For each passed in route...
		for (let i = 0; i < routes?.length; ++i) {
			// Set an id, which will be dot separated and begin with root
			// for example, root.Style Guide.Icons
			routes[i].id = id + NavDividerCharacter + routes[i].name;
			// Save the parent navigation menu
			routes[i].parentNav = parentNav;
			// If we have children...
			if (routes[i].children) {
				// Recursively call this function and add child routes to list
				flatRoutes.push(...this.getFlatMenu(
					routes[i].children,
					routes[i].id,
					routes[i]
				));
			}
			// If we have a child nav item...
			if (routes[i].childNav) {
				// Recursively call this function and add child routes to list
				flatRoutes.push(...this.getFlatMenu(
					[routes[i].childNav],
					routes[i].id,
					routes[i]
				));
			}
			flatRoutes.push(routes[i]); // Add the current route to the nav
		}
		return flatRoutes;
	}

	/**
	 * Find a menu by URL.
	 * @param url The URL to search by
	*/
	getMenuByUrl(url: string) {
		// An empty url indicates we are in the root nav
		if (url === "") { return null; }

		const flatMenu = this.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes()));

		let myRoute = flatMenu.find((o) =>
			(
				('/' + (o.href ?? o.path)) === url 
				&& o.exact
			) 
			|| (
				(o.href ?? o.path) 
				&& url.startsWith('/' + (o.href ?? o.path)) 
				&& !o.exact
			)
		);

		if (!myRoute) {
			let urlSegments = '';
			url.split("/").filter((o) => o.length > 0).forEach((o) => {
				urlSegments += o + "/";
				let temp = flatMenu.filter((m) => (m.href ?? m.path)?.startsWith(urlSegments));
				if (temp.length > 0) { myRoute = temp[temp.length - 1]; }
			});
			return myRoute;
		}

		if (myRoute?.parentNav) { myRoute = myRoute.parentNav; }

		return myRoute; // If nothing was found, return undefined
	}

	getCurrRouteIndex(navRoutes: NavRoute[]) {
		return navRoutes?.map((o, i) => ({
			name: o.name
			, id: o.id
			, i: o.index ?? i
			, parent: o.parentNav
			, activeNavLink: o.activeNavLink
		}))
		?.find((o) =>
			o?.id === this.currNav()?.parentNav?.id 
			|| o?.id === this.currForceSelect()
		)?.i;
	}

	/**
	 * Used to determine whether or not route link
	 * should show in top and secondary navigations
	*/
	shouldShowRouteLink(
		route: NavRoute
		, navRoutes: NavRoute[]
		, index: number = null
	) {
		return (
			route 
			&& (
				route.path 
				|| route.path === ""
			) 
			&& this.shouldDisplay(route, navRoutes, index)
		);
	}

	shouldDisplay(
		route: NavRoute
		, navRoutes: NavRoute[]
		, index: number = null
	) {
		return (
			(
				route?.children == null 
				|| route?.children?.length > 0
			) 
			&& (
				!route?.shouldDisplay 
				|| route?.shouldDisplay(index ?? this.getCurrRouteIndex(navRoutes))
			)
		);
	}

	/** Used in the function above to fix array for functions above */
	private getMenuNameWithRoot(newMenu: string[]) {
		let menuList = ["root"];
		// fix: hidden TypeError
		if (
			!FormatHelperService.GetIsNully(newMenu) 
			&& newMenu[0] !== "root"
		) { menuList = menuList.concat(newMenu); } 
		else { menuList = newMenu; }
		return menuList;
	}

	/**
	 * Toggles the fixed state of the navigation menu.
	 * If the navigation is fixed, it will be set to not fixed and the fixed state will be stored in localStorage.
	 * If the navigation is not fixed, it will be set to fixed and the fixed state will be stored in localStorage.
	 * If the navigation is not fixed and the menu is not currently open, it will also collapse all sub-navigation items.
	 * @param event - (Optional) A boolean value to explicitly set the fixed state of the navigation menu.
	*/
	toggleNavFixed(event?: boolean) {
		this.isNavFixed.set(event ?? !this.isNavFixed());
		localStorage.setItem("isNavFixed", this.isNavFixed().toString());
		this.gs.isNavFixed.next(this.isNavFixed());
		this.toggleNavOpen(this.isNavFixed());
	}

	/**
	 * Toggles the navigation open or closed with a delay.
	 * If the navigation is not fixed and the menu is not currently open, it will also collapse all sub-navigation items.
	 * The `navService.toggleNavOpen()` method is called after the timeout, with the `open` parameter passed to this method.
	 * @param event - (Optional) A boolean value to explicitly set the open state of the navigation menu.
	*/
	toggleNavOpen(
		event?: boolean
		, ignoreDelay: boolean = false
	) {
		if (ignoreDelay) { this.toggleNavOpenDebounce(event); }
		else { this._isNavOpen$.next(event); }
	}

	private toggleNavOpenDebounce(event?: boolean) {		
		event = (event ?? !this.isNavOpen()) || this.isNavFixed();

		if (event != this.isNavOpen()) {
			this.isNavOpen.set(event);
			this.gs.isNavOpen.set(event);
			if (!event) { this.collapseAllSubNavs(); }
		}	
	}

	/**
	 * Toggles the expansion state of the provided navigation route.
	 * If the route is currently expanded, it will be collapsed recursively.
	 * If the route is not expanded, it will be expanded and all other sub-navigation items will be collapsed.
	 * @param navRoute - The navigation route to toggle the expansion state for.
	*/
	toggleSubNav(navRoute: NavRoute) {
		if (navRoute.expanded) { this.collapseSubNavRecursively(navRoute); } 
		else {
			navRoute.expanded = true;
			this.collapseAllSubNavs(navRoute);
		}
	}

	/**
	 * Collapses all sub-navigation items except the one specified by the `keepOpen` parameter.
	 * @param keepOpen - (Optional) The navigation route to keep open while collapsing the others.
	 * @param navRoutes - (Optional) The list of navigation routes to collapse. If not provided, it defaults to `this.navRoutes()`.
	*/
	collapseAllSubNavs(
		keepOpen?: NavRoute
		, navRoutes?: NavRoute[]
	) {
		// Default to navRoutes signal if nothing is passed in
		navRoutes ??= this.navRoutes();

		// Collapse all sub navs except keepOpen if passed in
		navRoutes?.filter((o) => o.expanded)
			?.forEach((navItem) => {
				// Getting the entire navigation tree for the current navItem.
				let tree = this.getNavTreeRecursively(navItem);
				// If keepOpen is not in the tree, collapse the navItem and all sub navs.
				if (!tree.includes(keepOpen)) { this.collapseSubNavRecursively(navItem); }
				// If keepOpen is in the tree, keep the navItem open and run this function again to close any sub nav siblings.
				else if (navItem.children?.length > 0) { this.collapseAllSubNavs(keepOpen, navItem.children); }
			}
		);
		this.userProfileExpanded.set(false);
	}

	/**
	 * Recursively collapses the sub navigation for the given navigation route.
	 * @param route - The navigation route to collapse the sub navigation for.
	*/
	collapseSubNavRecursively(route: NavRoute) {
		route.expanded = false;
		route.children?.forEach((o) => { this.collapseSubNavRecursively(o); });
	}

	/**
	 * Recursively retrieves the navigation tree for the given navigation route.
	 * @param navRoute - The navigation route to retrieve the tree for.
	 * @returns An array of navigation routes representing the entire navigation tree.
	*/
	getNavTreeRecursively(navRoute: NavRoute) {
		const navTree = [];
		navTree.push(navRoute);
		navRoute.children?.forEach((o) => { navTree.push(...this.getNavTreeRecursively(o)); });
		return navTree;
	}

	/**
	 * Determines whether the current user has access to view the navigation and all sub-navigation for the given route.
	 * @param route - The navigation route to check access for.
	 * @returns `true` if the user has access to view the nav and all sub-navigation, `false` otherwise.
	*/
	canViewNav(route: NavRoute): boolean {
		let hasAccess = (route.children?.length ?? 0) === 0; // If no children, return true
		route.children?.forEach((o) => { if (this.canViewNav(o)) { hasAccess = true; } });
		return hasAccess && this.hasNavAccess(route);
	}

	/**
	 * Determines whether the current user has access to view the navigation for the given route based on the given module and permission roles.
	 * @param route - The navigation route to check access for.
	 * @returns `true` if the user has access to view the navigation, `false` otherwise.
	*/
	hasNavAccess(route: NavRoute): boolean {
		let hasAccess = !route.moduleAccess;
		route.moduleAccess?.forEach((o) => { if (this.sec?.hasModuleAccess(o.modules, o.permissionRoles)) { hasAccess = true; } });

		return hasAccess;
	}

	parseUrl(url: string) {
		let currentNav: NavRoute = null;

		if (url.startsWith("/")) { url = url.slice(1); }
			
		this.navRoutes().forEach((navItem) => {
			if (
				url.startsWith(navItem.area) 
				|| url.includes("error-report/error-reports/list/" + navItem.module) 
				|| url.includes("error-report/error-reports/edit/" + navItem.module)
			) {
				this.selectedMainNav.set(navItem.name);
				this.currSelectedMainNavMenu.set(navItem);
				
				if (
					url == navItem.path 
					|| url == navItem.href
				) { currentNav = navItem; }
			} 
			
			if (
				navItem?.children 
				&& url !== navItem.path 
				&& url != navItem.href
			) {
				let routes = this.getFlatMenu([navItem])?.filter(o => url.startsWith(o.activeNavLink))
					.sort((a, b) => b.activeNavLink.length - a.activeNavLink.length);

				if (routes.length > 0) {
					let route = routes[0];
					this.currForceSelect.set(routes[0].id);
					this.currOpenSecondaryNavMenu.set(routes[0]);
					this.currSelectedMainNavMenu.set(navItem);
					currentNav = route.parentNav ? route.parentNav : route;
				}
			}
		});

		if (url.startsWith(`admin/users/user/edit/${this.sec.getUser()?.id}`)) { this.selectedMainNav.set("profile"); }

		if (
			url === "dashboard" 
			|| url === ""
		) {
			this.selectedMainNav.set("dashboard");	
			this.currSelectedMainNavMenu.set(rootNav);
		}
		
		this.currNav.set(currentNav);
	}

    toggleNotificationsNav() {
        this.isNotificationsNavOpen.update((isOpen) => !isOpen);
    }
}
