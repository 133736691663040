<div class="d-flex justify-content-between">
    <h1 class="mb-2">Inventories</h1>
    @if (
        !showEditInventory 
        && inactiveInventoryList.length != 0
    ) {
        <div class="d-flex align-items-center">
            <ui-switch 
                #inactive 
                [(ngModel)]='showInactiveInventories' 
                color='#007bff' 
                defaultBgColor='#EEE'
                size='medium' 
                id="inactive" 
                class="mb-1"
            ></ui-switch>
            <label 
                for='inactive' 
                ngbTooltip='Toggle to show inactive Inventories'
                class="for ml-2"
            >Show Inactive?</label>
        </div>
    }
</div>

@if (!showEditInventory) {
    <div class="d-flex flex-column">
        <!-- Top row -->
        <div 
            class="topRow invRow hover bg-secondary d-flex align-items-center" 
            (click)="editInventory(0)"
        >
            <fa-icon 
                class="fa-lg text-white ml-2" 
                [icon]="faIcon.faPlus"
            ></fa-icon>
            <p class="ml-2 my-auto text-white">Add Inventory</p>
        </div>

        <!-- All other rows -->
        @for (inv of inventoryList; track inv.sysInvInventoryId) {
            <!-- Conditionally hide inactive inventories -->
            @if (
                inv.active == true 
                || showInactiveInventories
            ) {
                <div 
                    class="invRow hover d-flex justify-content-between align-items-center" 
                    (click)="editInventory(inv.sysInvInventoryId)"
                >
                    <div class="d-flex flex-wrap col-md-12 p-0">
                        <div class="d-flex flex-wrap col-md-3 p-0">
                            <p class="rowFields ml-2 my-auto"><strong>Name:</strong></p>
                            <p class="my-auto">{{ inv.name }}</p>
                        </div>

                        <div class="d-flex flex-wrap col-md-3 p-0">
                            <p class="rowFields ml-2 my-auto"><strong>Type:</strong></p>
                            <p class="my-auto">{{ parseEnum(inv.type) }}</p>
                        </div>

                        <div class="d-flex flex-wrap col-md-4 p-0">
                            <p class=" ml-2 my-auto"><strong>Programs:</strong></p>
                            @if (inv.programs.length <= 2) { <p class="ml-2 my-auto">{{ inv.programs.slice(0, 2).join(', ') }}</p> }
                            @else { 
                                <p class="ml-2 my-auto">
                                    <button
                                        class="btn btn-outline-dark"
                                        type="button"
                                        (click)="$event.stopPropagation(); showPrograms(inv)"
                                    >View {{ inv.programs.length }} Associations </button>
                                </p>
                            }
                        </div>
                        @if (showInactiveInventories) {
                            <div class="d-flex flex-wrap col-md-2 p-0">
                                <p class="rowFields my-auto ml-2"><strong>Active:</strong></p>
                                <p class="my-auto">{{ inv.active ? "Yes" : "No" }}</p>
                            </div>
                        }
                    </div>

                    <!-- will need to update styling once implemented -->
                    @if (inv.canDelete) {
                        <pcg-delete-button 
                            [confirmMessage]="'Are you sure you want to delete this inventory?'"
                            [id]='inv.sysInvInventoryId'
                            url='api/Facility/Inventory/Delete'
                            (delete)="updateInventoryList($event)" 
                        ></pcg-delete-button>
                    }
                </div>
            }     
        }
    </div>
} @else {
    <!-- Form -->
    <div class="invRow border border-secondary">
        <fa-icon 
            [icon]="faIcon.faXmark" 
            class="fa-lg float-right" 
            (click)="showEditInventory = !showEditInventory;"
        ></fa-icon>

        <form 
            [formGroup]="fg" 
            pcgSubmitUrl="api/Facility/Inventory/Save"
            (messageSuccess)="handleSave($event)"
        >
            <input type='hidden' formControlName='sysInvInventoryId' />
            <input type='hidden' formControlName='facilityId' />
            <input type='hidden' formControlName='createdByUserId' />
            <input type='hidden' formControlName='createdDate' />

            <div class="row mt-1">
                <pcg-control-group 
                    class="col-md-4" 
                    label="Name"
                >
                    <input 
                        pcgControlRef 
                        formControlName="name" 
                        class="form-control" 
                        type="text" 
                    />
                </pcg-control-group>

                <pcg-control-group 
                    class="col-md-2" 
                    label="Type"
                >
                    <pcg-select pcgControlRef
                        formControlName="type"
                        [items]="types"
                        placeholder='Select...'
                    ></pcg-select>
                </pcg-control-group>
                @if(orgFacility.facilityTypeId == null) {
                    <pcg-control-group 
                        class="col-md-5"
                        label="Programs Association">
                        <pcg-select 
                            pcgControlRef
                            [multiple]='true' 
                            dataSource='api/Select/InvConfigPrograms'
                            formControlName='programIds' 
                            placeholder="Select at least one Program"
                            itemsShowLimit="3"
                            [selectedItemIds]="fg.controls.programIds.value"
                            [closeOnSelect]='false'
                        ></pcg-select>
                    </pcg-control-group>
                    <div class="d-flex flex-wrap col-md-1 p-0 mt-4">
                        <button
                        type="button"
                        class="btn btn-sm btn-save text-nowrap"
                        ngbTooltip="Add all programs."
                        (click)="AddAllPrograms()"
                        >
                            <fa-icon [icon]="faIcon.faPlus"></fa-icon> All
                        </button>
                    </div>
                } @else {
                    <pcg-page-info class="col-md-4 mt-3">
                        Program associations have been set by the Facility Type. If you believe
                        you require access to another program please submit a help ticket.
                    </pcg-page-info>
                }
            </div>

            <div class="row justify-content-center">
                <button 
                    type="submit" 
                    class="btn btn-save btn-lg my-4 mx-2"
                >{{ fg.value.sysInvInventoryId === 0 ? "Create": "Save" }}</button>

                @if (fg.value.sysInvInventoryId !== 0) {
                    <button
                        class="btn my-4"
                        type="button"
                        [class.btn-outline-danger]="fg.value.active"
                        [class.btn-outline-success]="!fg.value.active"
                        (click)="activateDeactivate()"
                    >{{ fg.value.active ? 'Deactivate' : 'Reactivate'}}</button>
                }
            </div>
        </form>
    </div>
}

<ng-template 
    #modalProgram 
    let-modal
>
    <div class="modal-header d-flex">
        <h5 class="modal-title col-md-7">Programs</h5>
        @if (inventoryList.length > 1) {        
            <div class="form-ui-switch">
                <ui-switch 
                    [(ngModel)]= "allPrograms" 
                    id="all"(change)="toggleProgramView()"
                ></ui-switch>
                <label 
                    ngbTooltip='If checked, modal will display all inventories and their programs for the facility.'
                    for='all' 
                    class="form-ui-switch-label"
                >Show All</label>
            </div>
        }
        <button 
            type="button" 
            class="close" 
            aria-label="Close" 
            (click)="modal.dismiss();"
        ><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body">
        @if (!allPrograms) {
            <ul>
                <li>
                    <strong>{{ inv.name}}:</strong>
                    <ul>
                        <li *ngFor="let program of inv.programs">{{ program }}</li>
                    </ul>
                </li>
            </ul>
        } @else {
            @if (showInactiveInventories) { <strong>Active:</strong> }
            <ul>
                <li *ngFor="let inv of activeInventoryList">
                    <strong>{{ inv.name }}:</strong>
                    <ul>
                        <li *ngFor="let program of inv.programs">{{ program }}</li>
                    </ul>
                </li>
            </ul>
            @if (showInactiveInventories) {
                <strong>Inactive:</strong>
                <ul>
                    <li *ngFor="let inv of inactiveInventoryList">
                        <strong>{{ inv.name }}:</strong>
                        <ul>
                            <li *ngFor="let program of inv.programs">{{ program }}</li>
                        </ul>
                    </li>
                </ul>
            }
        }
    </div>

    <div class="modal-footer">
        <button 
            type="button" 
            class="btn btn-secondary" 
            (click)="modal.dismiss();"
        >Close</button>
    </div>
</ng-template>
